@import "../../variables";
@import "../../../../../node_modules/bootstrap-v4-rtl/scss/functions";
@import "../../../../../node_modules/bootstrap-v4-rtl/scss/variables";
@import "../../../../../node_modules/bootstrap-v4-rtl/scss/mixins";
@import "../../../../../node_modules/bootstrap-v4-rtl/scss/forms";
@import "../bootstrap/custom-forms";
@import "../../../../../node_modules/bootstrap-v4-rtl/scss/input-group";

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px lighten($success, 40%) inset;
}

// Disabled input bg
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
}

.input-group>.form-control:disabled,
.input-group> .form-control[readonly] {
  background-color: #e9ecef !important;
}

// Plugin if (formFieldDependency)
[data-if]{
  display:none
}

[data-if][data-opacity]{
  display: block !important;
}

// Maps
.map_wrap{
  border: 1px solid $gray-300;
  width: 100%;
  height: 200px;
  background-image: url(../../imgs/daftra_map.svg);
  background-size: cover;
  position: relative;
  > .map_wrap_btn {
    background: rgba(0,0,0,0.3);
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// Legends
.legend {
  border-style: none;
  border-width: 0;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0;
  width: auto;
  padding: 0 10px;
  border: 1px solid #e0e0e0;
}
.fieldset {
  border: 1px solid #e0e0e0;
  padding: 10px;
  background: #f4f8fb;
}

.form-group label{
  color: $inactive;
  font-weight: bold !important;
  font-size: 14px;
}

.input-group>.custom-file,
.input-group>.custom-select,
.input-group>.form-control,
.input-group>.form-control-plaintext {
  background-color: #fff !important;
}
.input-group .btn.dropdown-toggle{
  background: #E4EBF2;
}
.input-group .btn.dropdown-toggle .caret{
  margin: 0  0 0 5px;
}
.input-group>.position-relative{
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .input-group.mob-block,
  .input-group-prepend,
  .input-group.mob-block .btn,
  .input-group.mob-block .position-relative,
  .input-group.mob-block input{
    display: block;
    width: 100%;
  }
  .input-group.mob-block .btn,
  .input-group.mob-block .position-relative{
    margin-bottom: 15px;
  }
}
.form-control-hover{
  &:hover{
    background: $disabled;
  }
}
label {
  margin-bottom: 7px;
  // line-height: 1;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px lighten($success, 40%) inset !important;
  -webkit-box-shadow: 0 0 0 30px lighten($success, 55%) inset !important;
}
input[type="number"].form-control::-webkit-outer-spin-button,
input[type="number"].form-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"].form-control{
  -moz-appearance: textfield;
}
// .custom-switch .custom-control-label:before {
//   background-color: #75799D;
// }
// .custom-switch .custom-control-label:after {
//   background-color: #fff !important;
// }
// .custom-switch .custom-control-label:after,
// .custom-switch .custom-control-label:before{
//   cursor: pointer;
//   top: 0;
// }
// .custom-switch .custom-control-label:after {
//   top: calc(.15625rem - 1px) !important;
// }
// .custom-switch .custom-control-input:checked~.custom-control-label:before {
//   color: #fff;
//   border-color: #80C342;
//   background-color: #80C342;
// }
// .custom-switch .custom-control-input:focus~.custom-control-label:before {
//   box-shadow: 0 0 0 0.2rem rgba(128, 195, 66, 0.25);
// }
// .custom-switch .custom-control-input:not(:disabled):active~.custom-control-label:before {
//   color: #fff;
//   background-color: #a0a4cc !important;
//   border-color: #a0a4cc !important;
// }
// .custom-switch.lg {
//   padding-left: 2.3rem;
//   min-height: 1.7rem;
// }
// .custom-switch.lg .custom-control-label:before {
//   width: 3.2rem;
//   height: 1.7rem;
//   border-radius: 2rem;
// }
// .custom-switch.lg .custom-control-label:after {
//   top: calc(.25rem + 3px) !important;
//   left: calc(-2.25rem + 6px);
//   width: calc(1.15rem - 4px);
//   height: calc(1.15rem - 4px);
// }
// .custom-switch.lg .custom-control-input:checked~.custom-control-label:after {
//   -webkit-transform: translateX(1.5rem);
//   transform: translateX(1.5rem);
// }
.form-group-icon {
  position: relative;
  .form-control {
    padding-left: 35px;
  }
  .input-icon {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 17px;
    color: lighten($text-main, 40%);
    pointer-events: none
  }
  &.reverse{
    .form-control {
      padding-left: 0.75rem;
      padding-right: 35px;
    }
    .input-icon {
      left: inherit;
      right: 10px;
    }
  }
}
.form-group-placeholder{
  position: relative;
  .form-control {
    padding-right: 80px;
  }
  .input-placeholder {
    position: absolute;
    top: 12px;
    right: 14px;
    line-height: 1;
    font-size: 14px;
    color: lighten($text-main, 40%);
    pointer-events: none;
  }
  &.reverse{
    .form-control {
      padding-left: 0.75rem;
      padding-right: 110px;
    }
    .input-placeholder {
      right: 42px;
    }
  }
}
.custom-select {
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='1024' height='640'><path d='M1017 68L541 626q-11 12-26 12t-26-12L13 68Q-3 49 6 24.5T39 0h952q24 0 33 24.5t-7 43.5z'></path></svg>") no-repeat right .75rem center/8px 6px !important;
}
.custom-radio,
.custom-checkbox{
  .invalid-message,
  .invalid-messages{
    padding: 0;
  }
}
.checkbox-sm{
  width: 35px;
  height: 36px;
  .custom-control-inline{
    margin: 0;
  }
}
.small-checkbox {
  .custom-control {
    padding-left: 0;
    line-height: 1;
  }
  .custom-control-inline{
    margin-right: 0;
    width: 16px;
  }
  .custom-control-label:after,
  .custom-control-label:before{
    left: 0;
  }
}

[disabled],
.disabled,
div.disabled,
span.disabled,
input.disabled,
textarea.disabled,
select.disabled {
  opacity: .5;
  pointer-events:none;
  * {
    pointer-events:none;
  }
}

// Helper Classes
@media (max-width: 768px) {
  .w-sm-auto {
    width: auto;
  }
  .w-sm-100 {
    width: 100% !important;
  }
}

.pointer{
  cursor: pointer;
}

// Select 2 Icon
.form-group-icon .select2-container .select2-selection--single .select2-selection__rendered{
  padding-left: 35px;
}
.form-group-icon .select2-container--bootstrap4 .select2-selection--single .select2-selection__arrow b{
  display: none;
}
.form-group-icon .select2-container .select2-selection--multiple .select2-selection__rendered {
  padding-left: 35px !important;
}

// Select 2 Multiple
.select2-selection--multiple{
  display: block;
  padding-left: 12px;
  padding-right: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #8186b5;
  //line-height: calc(1.4em + .75rem + 2px);
  .select2-selection__rendered{
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .select2-search__field{
    margin-top: 0 !important;
    color: #8186b5;
    &::placeholder{
      color: #8186b5;
    }
  }
  .select2-selection__choice{
    line-height: 1;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
}
.has-error .select2-selection--multiple .select2-search__field,
.has-error .select2-selection--multiple .select2-search__field:focus,
.has-error .select2-selection--multiple .select2-search__field:active{
  box-shadow: none !important;
  border: none;
}

// Required
span.required{
  color: red;
  margin-left: 2px;
  margin-right: 2px;
}

// Validation
.has-error .select2-selection,
.has-error input,
.has-error textarea{
  border-color: red !important;
}

.has-error .select2-selection:focus,
.has-error .select2-container--focus .select2-selection,
.has-error input:focus,
.has-error textarea:focus{
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 0, 1, 0.25) !important;
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 1, 0.25) !important;
}

.has-error .select2-container--bootstrap4.select2-container--focus .select2-selection {
  border-color: red!important;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 0, 1, 0.25) !important;
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 1, 0.25) !important;
}

.invalid-message{
  padding: 0;
  margin: 0;
  padding-top: 5px;
  list-style-type: none;
  span{
    color: #ce0000;
    font-size: $font-size-sm;
  }
}

.deactivated{
  opacity: 0.5;
}

.invalid-messages{
  max-height: 23px;
  padding-top: 5px;
  overflow: hidden;
  .invalid-message{
    padding: 0;
  }
}

// Textarea
textarea{
  min-height: 38px;
  //max-height: 350px;
}

.hide-backend-error .invalid-message.backend-error{
  display: none;
}

// Currency inputs
.currency-selector {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding-left: .5rem;
  border: 0;
  background: transparent;
  -webkit-appearance:none;
  -moz-appearance:none;
  appearance: none;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='1024' height='640'><path d='M1017 68L541 626q-11 12-26 12t-26-12L13 68Q-3 49 6 24.5T39 0h952q24 0 33 24.5t-7 43.5z'></path></svg>") 90%/12px 6px no-repeat;
  font-family: inherit;
  color: inherit;
  border: 1px solid #ced4da;
}

.currency-symbol{
  width: 30px;
  font-size: 20px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  border: 1px solid #ced4da;
  border-right: none;
}

.currency-amount {
  text-align: left;
}

.currency-addon {
  width: 6em;
  text-align: left;
  position: relative;
}

// Rating input
.rating-input{
  .rating-stars{
    color: #aaa;
    line-height: 1;
    font-size: 35px;
    direction: ltr;
    .rate-base-layer{
      color: #aaa;
    }
    .rate-hover-layer{
      color: orange;
    }
  }
}

// Timepicker
.ui-timepicker-wrapper {
  overflow-y: auto;
  max-height: 150px;
  width: 6.5em;
  background: #fff;
  border: 1px solid #ddd;
  -webkit-box-shadow:0 5px 10px rgba(0,0,0,0.2);
  -moz-box-shadow:0 5px 10px rgba(0,0,0,0.2);
  box-shadow:0 5px 10px rgba(0,0,0,0.2);
  outline: none;
  z-index: 10052;
  margin: 0;
  min-width: 100%;
}

.ui-timepicker-wrapper ul li {
  padding: 6px 8px;
}

.ui-timepicker-wrapper.ui-timepicker-with-duration {
  width: 13em;
}

.ui-timepicker-wrapper.ui-timepicker-with-duration.ui-timepicker-step-30,
.ui-timepicker-wrapper.ui-timepicker-with-duration.ui-timepicker-step-60 {
  width: 11em;
}

.ui-timepicker-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ui-timepicker-duration {
  margin-left: 5px; color: #888;
}

.ui-timepicker-list:hover .ui-timepicker-duration {
  color: #888;
}

.ui-timepicker-list li {
  padding: 3px 0 3px 5px;
  cursor: pointer;
  white-space: nowrap;
  color: #000;
  list-style: none;
  margin: 0;
}

.ui-timepicker-list:hover .ui-timepicker-selected {
  background: #fff; color: #000;
}

li.ui-timepicker-selected,
.ui-timepicker-list li:hover,
.ui-timepicker-list .ui-timepicker-selected:hover {
  background: #1980EC; color: #fff;
}

li.ui-timepicker-selected .ui-timepicker-duration,
.ui-timepicker-list li:hover .ui-timepicker-duration {
  color: #ccc;
}

.ui-timepicker-list li.ui-timepicker-disabled,
.ui-timepicker-list li.ui-timepicker-disabled:hover,
.ui-timepicker-list li.ui-timepicker-selected.ui-timepicker-disabled {
  color: #888;
  cursor: default;
}

.ui-timepicker-list li.ui-timepicker-disabled:hover,
.ui-timepicker-list li.ui-timepicker-selected.ui-timepicker-disabled {
  background: #f2f2f2;
}

// Time range
.time-range .icon-seperator i,
.number-range .icon-seperator i{
  margin-top: 10px;
  font-size: 18px;
  margin-left: 10px;
  margin-right: 10px;
}

// Checkboxes and radio
.input-group-text .custom-control .custom-control-label,
.input-group-text .custom-control-inline{
  display: block;
  width: 100%;
  text-align: left;
}

// File uploader
.file-uploader{
  .file-uploader-file-items{
    background: $gray-100;
    overflow: auto;
    border-radius: 6px;
    max-height: 220px;
    .file-uploader-file-item{
      display: flex;
      width: 100%;
      padding: 5px 10px;
      border-bottom: 1px solid $gray-200;
      &:hover{
        background: $secondary;
      }
      &:last-child{
        border-bottom: 1px solid transparent;
      }
      [target="_blank"]:after {
        display: none;
      }
      .file-uploader-file-item-preview{
        flex-grow: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        a{
          color: $inactive;
          font-weight: 700;
          display: flex;
          align-items: center;
          width: 100%;
          .file-uploader-file-item-preview-img{
            width: 30px;
            min-width: 30px;
            height: 30px;
            background: rgba(0, 0, 0, 0.05);
            border-radius: 50%;
            text-align: center;
            color: $gray-600;
            margin: 0 15px 0 5px;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            @include media-breakpoint-down(md) {
              margin: 0 10px;
            }
          }
          span{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          &:hover{
            color: darken($inactive, 20%);
          }
        }
      }
      .file-uploader-file-item-actions{
        font-size: 12px;
        text-transform: uppercase;
        white-space: nowrap;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        .size{
          margin: 0 35px;
          @include media-breakpoint-down(md) {
            display: none;
          }
        }
        .delete{
          border: none;
          background: none;
          padding: 0;
          margin: 0 15px 0 10px;
          font-size: 20px;
          vertical-align: middle;
          color: $danger;
          @include media-breakpoint-down(md) {
            margin: 0 10px;
          }
          &:hover{
            color: darken($danger, 30%);;
          }
        }
      }
    }
  }
  .file-uploader-ui {
    display: flex;
    flex-direction: column;
    padding: 0;
    min-width: 100%;
    max-width: 100%;
    overflow: hidden;
    height: 150px;
    border: 2px dashed #e4ebf2;
    border-radius: 6px;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
    transition: all .3s;
    .file-uploader-form {
      width: 100%;
      height: 100%;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      label{
        width: 100%;
        height: 100%;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-bottom: 0;
        i {
          display: block;
          margin: 0 12px;
          font-size: 34px;
          vertical-align: middle;
          color: #00b0ef;
        }
        .text-cyan {
          color: #00b0ef !important;
        }
      }
    }
    .file-uploader-preview-value {
      display: none;
      position: relative;
      width: 100%;
      height: 100%;
      .file-uploader-value:after {
        content: '';
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      .file-uploader-preview {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        .file-uploader-preview-container {
          background: #fafafa;
          border-radius: 50%;
          width: 130px;
          height: 130px;
          color: #00b0ef;
          line-height: 130px;
          margin: 10px auto;
          display: inline-block;
          font-size: 40px;
          border: 1px solid rgba(0, 0, 0, 0.05);
        }
      }
      .file-uploader-value {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        -webkit-transition: 0.3s all;
        -moz-transition: 0.3s all;
        -ms-transition: 0.3s all;
        -o-transition: 0.3s all;
        transition: 0.3s all;
        max-width: 100%;
        .file-uploader-value-container {
          position: relative;
          z-index: 2;
          max-width: 90%;
          p {
            margin-bottom: 5px;
            color: #fff;
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .file-uploader-actions .btn {
            padding: 0;
            width: 35px;
            height: 35px;
            line-height: 35px;
            margin: 2px;
            i {
              padding: 0;
              margin: 0;
              width: 22px;
              height: 22px;
              line-height: 1;
              font-size: 18px;
            }
          }
        }
      }
      &:hover .file-uploader-value {
        opacity: 1;
      }
    }
    &:hover{
      border: 2px dashed #b8d6f2;
      box-shadow: 0 0 35px 1px rgba(184,214,242,.3);
    }
  }
  &.hide-download .file-uploader-ui{
    .file-uploader-preview-value{
      .file-uploader-value{
        .file-uploader-value-container{
          .file-uploader-actions{
            .download{
              display: none;
            }
          }
        }
      }
    }
  }
  &.active{
    .file-uploader-file-items{
      margin-bottom: 20px;
    }
    .file-uploader-ui {
      .file-uploader-form {
        display: none;
      }
      .file-uploader-preview-value {
        display: block;
      }
    }
  }
  &.readonly .file-uploader-ui {
    .file-uploader-preview-value {
      .file-uploader-value{
        .file-uploader-actions{
          .edit,
          .delete{
            display: none;
          }
        }
      }
    }
  }
  &.xs{
    .file-uploader-ui {
      height: 65px;
      .file-uploader-preview-value {
        .file-uploader-preview {
          .file-uploader-preview-container {
            width: 40px;
            height: 40px;
            line-height: 40px;
          }
        }
      }
    }
  }
}
.has-error .file-uploader .file-uploader-ui{
  border-color: #fd8383;
  box-shadow: 0 0 13px #fd83833b;
}

// media queries
.file-uploader .file-uploader-ui .file-uploader-preview-value .file-uploader-value{
  opacity: 1;
}
.file-uploader .file-uploader-ui .file-uploader-form .show-lg{
  display: none;
}
@media (min-width: 768px) {
  .file-uploader .file-uploader-ui .file-uploader-preview-value .file-uploader-value{
    opacity: 0;
  }
  .file-uploader .file-uploader-ui .file-uploader-form .show-lg,
  .file-uploader.sm .file-uploader-ui .file-uploader-form .hide-lg{
    display: inline;
  }
  .file-uploader .file-uploader-ui .file-uploader-form .hide-lg,
  .file-uploader.sm .file-uploader-ui .file-uploader-form .show-lg{
    display: none;
  }
}

// Image uploader
.image-uploader{
  .image-uploader-ui {
    display: flex;
    flex-direction: column;
    padding: 0;
    min-width: 100%;
    max-width: 100%;
    overflow: hidden;
    height: 150px;
    border: 2px dashed #e4ebf2;
    border-radius: 6px;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
    transition: all .3s;
    .image-uploader-form {
      width: 100%;
      height: 100%;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      label{
        width: 100%;
        height: 100%;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        i {
          display: block;
          margin: 0 12px;
          font-size: 34px;
          vertical-align: middle;
          color: #00b0ef;
        }
        .text-cyan {
          color: #00b0ef !important;
        }
      }
    }
    .image-uploader-preview-value {
      display: none;
      position: relative;
      width: 100%;
      height: 100%;
      .image-uploader-value:after {
        content: '';
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      .image-uploader-preview {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        .image-uploader-preview-container {
          background: #fafafa;
          border-radius: 50%;
          width: 146px;
          height: 146px;
          color: #00b0ef;
          line-height: 130px;
          margin: 10px auto;
          display: inline-block;
          font-size: 40px;
          border: 1px solid rgba(0,0,0,.05);
          img{
            max-width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .image-uploader-value {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        -webkit-transition: 0.3s all;
        -moz-transition: 0.3s all;
        -ms-transition: 0.3s all;
        -o-transition: 0.3s all;
        transition: 0.3s all;
        max-width: 100%;
        .image-uploader-value-container {
          position: relative;
          z-index: 2;
          p {
            margin-bottom: 5px;
            color: #fff;
            font-size: 16px;
          }
          .image-uploader-actions .btn {
            padding: 0;
            width: 35px;
            height: 35px;
            line-height: 35px;
            margin: 2px;
            i {
              padding: 0;
              margin: 0;
              width: 22px;
              height: 22px;
              line-height: 1;
              font-size: 18px;
            }
          }
        }
      }
      &:hover .image-uploader-value {
        opacity: 1;
      }
    }
    &:hover{
      border: 2px dashed #b8d6f2;
      box-shadow: 0 0 35px 1px rgba(184,214,242,.3);
    }
  }
  &.hide-download .image-uploader-ui{
    .image-uploader-preview-value{
      .image-uploader-value{
        .image-uploader-value-container{
          .image-uploader-actions{
            .download{
              display: none;
            }
          }
        }
      }
    }
  }
  &.active .image-uploader-ui {
    .image-uploader-form {
      display: none;
    }
    .image-uploader-preview-value {
      display: block;
    }
  }
  &.readonly .image-uploader-ui {
    .image-uploader-preview-value {
      .image-uploader-value{
        .image-uploader-actions{
          .edit,
          .delete{
            display: none;
          }
        }
      }
    }
  }
}
.has-error .image-uploader .image-uploader-ui{
  border-color: #fd8383;
  box-shadow: 0 0 13px #fd83833b;
}

// Avatar uploader
.avatar-uploader{
  .avatar-uploader-ui {
    display: flex;
    flex-direction: column;
    padding: 0;
    min-width: 100%;
    max-width: 100%;
    overflow: hidden;
    height: 114px;
    border: 2px dashed #e4ebf2;
    border-radius: 6px;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
    transition: all .3s;
    .avatar-uploader-form {
      width: 100%;
      height: 100%;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      label{
        width: 100%;
        height: 100%;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-bottom: 0;
        i {
          display: block;
          margin: 0 12px;
          font-size: 34px;
          vertical-align: middle;
          color: #00b0ef;
        }
        .text-cyan {
          color: #00b0ef !important;
        }
      }
    }
    .avatar-uploader-preview-value {
      display: none;
      position: relative;
      width: 100%;
      height: 100%;
      //.avatar-uploader-value:after {
      //  content: '';
      //  background: rgba(0, 0, 0, 0.5);
      //  position: absolute;
      //  top: 0;
      //  left: 0;
      //  width: 100%;
      //  height: 100%;
      //}
      .avatar-uploader-preview {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        .avatar-uploader-preview-container {
          background: #fafafa;
          border-radius: 50%;
          width: 100px;
          height: 100px;
          color: #00b0ef;
          line-height: 1;
          margin: 0;
          display: inline-block;
          font-size: 40px;
          border: 1px solid rgba(0,0,0,.05);
          img{
            max-width: 100%;
            object-fit: cover;
            border-radius: 50%;
            height: 100%;
          }
        }
      }
      .avatar-uploader-value {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 1;
        -webkit-transition: 0.3s all;
        -moz-transition: 0.3s all;
        -ms-transition: 0.3s all;
        -o-transition: 0.3s all;
        transition: 0.3s all;
        max-width: 100%;
        position: static;
        width: auto;
        padding: 0 15px;
        .avatar-uploader-value-container {
          position: relative;
          z-index: 2;
          p {
            margin-bottom: 5px;
            color: #fff;
            font-size: 16px;
          }
          .avatar-uploader-actions .btn {
            padding: 0;
            width: 35px;
            height: 35px;
            line-height: 35px;
            margin: 2px;
            i {
              padding: 0;
              margin: 0;
              width: 22px;
              height: 22px;
              line-height: 1;
              font-size: 18px;
            }
          }
        }
      }
      &:hover .avatar-uploader-value {
        opacity: 1;
      }
    }
    &:hover{
      border: 2px dashed #b8d6f2;
      box-shadow: 0 0 35px 1px rgba(184,214,242,.3);
    }
  }
  &.hide-download .avatar-uploader-ui{
    .avatar-uploader-preview-value{
      .avatar-uploader-value{
        .avatar-uploader-value-container{
          .avatar-uploader-actions{
            .download{
              display: none;
            }
          }
        }
      }
    }
  }
  &.active .avatar-uploader-ui {
    .avatar-uploader-form {
      display: none;
    }
    .avatar-uploader-preview-value {
      display: flex;
      align-items: center;
      justify-content: center;
      .avatar-uploader-preview{
        position: static;
        width: auto;
      }
    }
  }
  &.readonly .avatar-uploader-ui {
    .avatar-uploader-preview-value {
      .avatar-uploader-value{
        .avatar-uploader-actions{
          .edit,
          .delete{
            display: none;
          }
        }
      }
    }
  }
}
.has-error .avatar-uploader .avatar-uploader-ui{
  border-color: #fd8383;
  box-shadow: 0 0 13px #fd83833b;
}

// Formula
.formula-init-btn{
  top: 10px;
  right: 10px;
  bottom: inherit!important;
  z-index: 2;
  width: 31px;
  height: 31px;
  padding: 0!important;
  @include media-breakpoint-down(md) {
    padding: 0 !important;
    width: 31px;
    height: 31px;
    font-size: 0;
  }
  i{
    background: transparent;
  }
}

// Formula input
.mentions-input-box {
  position: relative;
  background: #fff;
  textarea {
    width: 100%;
    display: block;
    height: 38px;
    min-height: 50px;
    overflow: hidden;
    background: transparent;
    position: relative;
    resize: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-family: Open Sans,Helvetica,arial;
    &:focus {
      background-color: transparent !important;
    }
  }
  .mentions-autocomplete-list {
    display: none;
    background: #fff;
    border: 1px solid #b2b2b2;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 10000;
    margin-top: -2px;
    border-radius: 0 0 5px 5px;
    -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.14844);
    -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.14844);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.14844);
    max-height: 200px;
    overflow: auto;
    ul {
      margin: 0;
      padding: 0;
    }
    li {
      background-color: #fff;
      padding: 0 5px;
      margin: 0;
      width: auto;
      border-bottom: 1px solid #eee;
      height: 26px;
      line-height: 26px;
      overflow: hidden;
      cursor: pointer;
      list-style: none;
      white-space: nowrap;
      font-weight: normal;
      &:last-child {
        border-radius: 5px;
      }
      > {
        img, div.icon {
          width: 16px;
          height: 16px;
          float: left;
          margin-top: 5px;
          margin-right: 5px;
          -moz-background-origin: 3px;
          border-radius: 3px;
        }
      }
      em {
        font-weight: bold;
        font-style: none;
      }
      &:hover, &.active {
        background-color: #f2f2f2;
      }
      b {
        background: #ffff99;
        font-weight: normal;
      }
    }
  }
  .mentions {
    position: absolute;
    left: 1px;
    right: 0;
    top: 1px;
    bottom: 0;
    color: #fff;
    overflow: hidden;
    white-space: pre-wrap;
    word-wrap: break-word;
    padding: .375rem .75rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    font-family: Open Sans,Helvetica,arial;
    > div {
      color: #fff;
      white-space: pre-wrap;
      width: 100%;
      > strong {
        font-weight: normal;
        background: #d8dfea;
        > span {
          filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .mentions-input-box .mentions-autocomplete-list {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    max-height: unset;
    margin: 0;
  }
}

.form-control.formula-input,
.mentions-input-box .mentions{
  padding-right: 50px;
  &::placeholder{
    padding-top: 8px;
  }
  &:focus{
    &::placeholder{
      display: none !important;
      opacity: 0;
    }
  }
}

.formula-input-container {
  position: relative;
  .formula-input-tooltip {
    position: absolute;
    bottom: 110%;
    left: 0;
    right: 0;
    width: 100%;
    opacity: 0;
    background-color: #333;
    transition: opacity .35s ease .25s;
    border-radius: 8px;
    z-index: 1000;
    box-shadow: 0 5px 15px -5px rgba(0,0,0,.65);
    color: #fff;
    &.is-loading {
      overflow: visible;
      border-radius: 8px;
      &:before {
        background: #333;
        border-radius: 8px;
      }
      &:after {
        font-size: 18px;
      }
    }
    &.is-visible {
      opacity: 1;
    }
    .formula-input-tooltip-body {
      padding: 8px 12px;
      position: relative;
      min-height: 30px;
      line-height: 1.8;
      &:before {
        content: "";
        position: absolute;
        left: 50%;
        transform: translate(-50%,-12px);
        z-index: 1000;
        pointer-events: none;
        user-select: none;
        bottom: -28px;
        background: transparent;
        border: 8px solid transparent;
        border-top-color: transparent;
        border-top-color: #333;
      }
    }
  }
  // &:hover,
  // &:focus,
  // &:active {
  //   .formula-input-tooltip {
  //     opacity: 1;
  //   }
  // }
}

.formula-tag {
  background: #dbe7f3;
  border-radius: 4px;
  color: #3a3e63;
  display: inline-block;
  padding: 2px 8px;
  border: 1px solid #afc3d8;
  line-height: 1.2;
}

// media queries
.image-uploader .image-uploader-ui .image-uploader-preview-value .image-uploader-value{
  opacity: 1;
}
.image-uploader .image-uploader-ui .image-uploader-form .show-lg{
  display: none;
}
.avatar-uploader .avatar-uploader-ui .avatar-uploader-form .show-lg{
  display: none;
}
@media (min-width: 768px) {
  .image-uploader .image-uploader-ui .image-uploader-preview-value .image-uploader-value{
    opacity: 0;
  }
  .image-uploader .image-uploader-ui .image-uploader-form .show-lg,
  .image-uploader.sm .image-uploader-ui .image-uploader-form .hide-lg{
    display: inline;
  }
  .image-uploader .image-uploader-ui .image-uploader-form .hide-lg,
  .image-uploader.sm .image-uploader-ui .image-uploader-form .show-lg{
    display: none;
  }
  .avatar-uploader .avatar-uploader-ui .avatar-uploader-form .show-lg,
  .avatar-uploader.sm .avatar-uploader-ui .avatar-uploader-form .hide-lg{
    display: inline;
  }
  .avatar-uploader .avatar-uploader-ui .avatar-uploader-form .hide-lg,
  .avatar-uploader.sm .avatar-uploader-ui .avatar-uploader-form .show-lg{
    display: none;
  }
}
@media (max-width: 500px) {
  .avatar-uploader .avatar-uploader-ui .avatar-uploader-preview-value .avatar-uploader-preview{
    flex-direction: column;
  }
  .avatar-uploader .avatar-uploader-ui .avatar-uploader-preview-value .avatar-uploader-preview .avatar-uploader-preview-container{
    width: 65px;
    height: 65px;
    min-height: 65px;
  }
}

// Filtered items
[filtered="filtered"],
.select2-container--bootstrap4 .select2-selection.filtered{
  border-color: #00b0ef !important;
}


// Hide errors and required star
.form-group{
  &.hide-errors{
    .invalid-message,
    .invalid-messages{
      display: none !important;
    }
  }
  &.hide-required-star{
    .required{
      visibility: hidden;
    }
  }
}

// Float Labels
[data-float-label]{
  position: relative;
  &:after{
    border-radius: 5px;
    color: #a1a6ca;
    padding: 2px 6px;
    line-height: 1;
    background: #fff;
    content: attr(data-float-label);
    position: absolute;
    font-size: 12px;
    left: 10px;
    top: -10px;
    z-index: 99;
    pointer-events: none;
    transition: 0.3s all;
    opacity: 1;
    transform: translateY(0px);
    visibility: visible;
  }
  &:hover{
    &:after{
      opacity: 0;
      visibility: hidden;
      transform: translateY(5px);
    }
  }
}




.rtl{

  .invalid-messages{
    max-height: 25px;
  }

  .form-control{
    &::placeholder{
      text-align: right;
    }
  }

  .custom-select{
    padding: .375rem .75rem .375rem 1.75rem;
  }
  // .custom-switch{
  //   .custom-control-label{
  //     &:after{
  //       left: calc(-.5rem + 6px);
  //       right: inherit;
  //       top: 2px;
  //     }
  //   }
  //   &.lg{
  //     .custom-control-label{
  //       &:after{
  //         right: calc(-2.25rem + 6px);
  //       }
  //     }
  //     .custom-control-input:checked~.custom-control-label:after{
  //       transform: translateX(-1.5rem);
  //     }
  //   }
  // }
  .form-group-icon .input-icon{
    right: 10px;
    left: inherit;
  }
  .form-group-placeholder .form-control {
    padding-left: 80px;
    padding-right: 0.75rem;
  }
  .form-group-icon .form-control{
    padding-right: 35px;
    padding-left: 0.75rem;
  }
  .form-group-icon.reverse .form-control {
    padding-right: .75rem;
    padding-left: 35px;
  }
  .form-group-icon.reverse .input-icon {
    left: 10px;
    right: inherit;
  }
  .form-group-placeholder .input-placeholder{
    left: 14px;
    right: inherit;
  }
  .form-group-placeholder.reverse .input-placeholder{
    right: inherit;
    left: 42px;
  }
  .small-checkbox > .custom-control{
    padding-right: 0;
    margin-left: -0.5rem;
  }
  .currency-symbol{
    border-right: 1px solid #ced4da;
    border-left: none;
  }
  .SumoSelect>.optWrapper.multiple>.options li.opt{
    padding-right: 35px;
    padding-left: 6px;
  }
  .input-group-addon.color-picker-wrap{
    border-left: 1px solid #ced4da !important;
    border-right: none !important;
  }
  .SumoSelect>.optWrapper.multiple>.options li.opt span{
    margin-left: 0;
    margin-right: -35px;
  }
  .SumoSelect>.CaptionCont{
    padding: .375rem 12px .375rem 30px;
  }
  .SumoSelect>.CaptionCont>label{
    left: 0;
    right: inherit;
  }
  .SumoSelect>.CaptionCont>span{
    margin: 0;
    padding: 0;
  }
  .SumoSelect.open .search-txt{
    padding: .375rem 12px .375rem 30px;
  }
  .SumoSelect .select-all{
    padding-right: 35px;
    padding-left: 6px;
  }
  .SumoSelect .select-all>span{
    margin-left: 0;
    margin-right: -35px;
  }
  // Checkboxes and radio
  .input-group-text .custom-control .custom-control-label,
  .input-group-text .custom-control-inline{
    display: block;
    width: 100%;
    text-align: right;
  }
  .form-control.formula-input,
  .mentions-input-box .mentions{
    padding-left: 50px;
    padding-right: 0.75rem;
    &::placeholder{
      font-family: tahoma,arial;
    }
    &:focus{
      &::placeholder{
        font-family: tahoma,arial;
      }
    }
  }
  .mentions-input-box .mentions{
    padding-top: calc(.375rem - 1px);
  }
  .formula-init-btn {
    left: 10px;
    right: inherit;
  }

  // Sortable Dynamic Rows
  .sortable-dynamic-rows-wrapper {
    .sortable-dynamic-rows {
      .sortable-dynamic-rows-row {
        .formula-input,
        .mentions-input-box .mentions {
          padding-right: 0.75rem !important;
          padding-left: 50px !important;
        }
        .select2-container--bootstrap4 .select2-selection,
        input,
        textarea {
          .select2-selection__rendered{
            justify-content: flex-end;
          }
        }
        .formula-init-btn {
          left: 10px;
          right: inherit;
        }
        .has-error{
          &:after {
            left: 6px;
            right: inherit;
          }
        }
        .invalid-message{
          left: inherit;
          right: 0;
          //border-radius: 4px 0 0 0;
        }
      }
    }
  }

  // File Uploader
  .file-uploader{
    .file-uploader-file-items{
      .file-uploader-file-item{
        .file-uploader-file-item-preview{
          a{
            .file-uploader-file-item-preview-img{
              margin: 0 5px 0 15px;
            }
          }
        }
        .file-uploader-file-item-actions{
          .delete{
            margin: 0 10px 0 15px;
          }
        }
      }
    }
  }

  // Date Pickers
  .new_date_picker{
    &::placeholder {
      direction: rtl;
    }
  }

  // Float Label
  [data-float-label]:after {
    right: 10px;
    left: auto;
  }

}


input.form-control, select.form-control, textarea.form-control {
  @supports (-webkit-touch-callout: none) { 
    font-size: 16px;
   }
}