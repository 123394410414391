@import "../variables";
@import "../mixins";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/functions";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/variables";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/mixins";


.sticky-message {
    $this: &;
    height: 45px;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    background: #d2d9df;
    @include body('.shrinked-sidebar'){
      display: none;
    }
    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        padding: 0 15px;
        &[target="_blank"]::after {
            content: '';
            display: none;
        }
    }
    &--color-warning {
        background: #F7CC49;
        #{$this}__link {
            color: #000;
        }
    }
    &--color-danger {
        background: #e94560;
        #{$this}__link {
            color: #fff;
        }
    }
}