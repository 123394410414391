@import "../variables";
@import "../mixins";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/functions";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/variables";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/mixins";

.alert{
  &.alert-bordered{
    border-width: 2px;
  }
  &.alert-dismissible{
    @include rtl(){
      padding-left: 4rem;
      padding-right: inherit;
    }
    .close {
      margin: .75rem 1.25rem;
      padding: 0;
      width: 24px;
      height: 24px;
      line-height: 14px;
      border-radius: 50%;
      border: 2px solid;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      @include ltr(){
        font-size: 1.3rem;
      }
      @include rtl(){
        right: inherit;
        left: 0;
        font-size: 1rem;
      }
      &:focus{
        outline: none;
      }
    }
  }
  .alert-icon{
    display: flex;
    align-items: center;
  }
  .alert-collapse-btn{
    button{
      text-decoration: underline;
    }
  }
  .alert-link{
    text-decoration: underline;
    font-weight: 600;
  }
  #errorsCollapse{
    .simplebar-scroll{
      max-height: 150px;
    }
  }
}
