@import "../variables";
@import "../mixins";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/functions";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/variables";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/mixins";


/****************************
 ****      Fonts
****************************/

@font-face {
  font-family: 'Roboto';
  src: url('../webfonts/Roboto-Black.woff2') format('woff2'),
  url('../webfonts/Roboto-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../webfonts/Roboto-Regular.woff2') format('woff2'),
  url('../webfonts/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../webfonts/Roboto-Medium.woff2') format('woff2'),
  url('../webfonts/Roboto-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../webfonts/Roboto-Light.woff2') format('woff2'),
  url('../webfonts/Roboto-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../webfonts/Roboto-Bold.woff2') format('woff2'),
  url('../webfonts/Roboto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
