@import "../../../node_modules/bootstrap-v4-rtl/scss/variables";
@import "../../../node_modules/bootstrap-v4-rtl/scss/mixins";
@import "variables";

//@mixin media-breakpoint-height-down($name, $breakpoints: $grid-breakpoints) {
//  $max: breakpoint-max($name, $breakpoints);
//  @if $max {
//    @media (max-height: $max) {
//      @content;
//    }
//  } @else {
//    @content;
//  }
//}

@mixin media-min($media) {
  @media only screen and (min-width: $media) { @content; }
}

@mixin media-max($media) {
  @media only screen and (max-width: $media) { @content; }
}

@mixin body($bodyClassOrId: '') {
  body#{$bodyClassOrId} &{
    @content;
  }
}

@mixin rtl($bodyClassOrId: '') {
  .rtl#{$bodyClassOrId} &{
    @content;
  }
}

@mixin ltr($bodyClassOrId: '') {
  .ltr#{$bodyClassOrId} & {
    @content;
  }
}
