@import "../variables";
@import "../mixins";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/functions";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/variables";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/mixins";

.main-nav {
  top: 0px;
  position: fixed;
  width: 210px;
  bottom: 0;
  height: 100%;
  padding: 0 0 0;
  background: $side-menu-bg;
  transition: $transition-base;
  flex: 0 0 auto;
  z-index: 10000;
  .simplebar-content-wrapper,
  .simplebar-mask{
    @include body('.shrinked-sidebar'){
      overflow: visible !important;
    }
  }
  @include ltr(){
    left: 0;
    border-right: 1px solid #e0e0e0;
  }
  @include rtl(){
    right: 0;
    left: auto;
    border-left: 1px solid #e0e0e0;
    border-right: none;
  }
  @include body('.modal-open'){
    z-index: 100;
  }
  @include body('.shrinked-sidebar'){
    padding-top: 48px;
    width: 60px;
    overflow: visible;
    position: static;
    height: auto;
  }
  .logo {
    background: #fff;
    overflow: hidden;
    transition: $transition-base;
    @include body('.shrinked-sidebar'){
      display: none;
    }
    .logo-img {
      padding: 20px 30px;
      height: 107px;
      font-size: 16px;
      line-height: 28px;
      text-decoration: none;
      color: #333;
      display: block;
      text-align: center;
      img {
        max-width: 100% !important;
        height: auto;
        max-height: 100%;
      }
    }
  }
  .nav-scroll {
    overflow: auto;
    position: relative;
    @include body('.shrinked-sidebar'){
      overflow: visible;
      height: auto !important;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    ul {
      list-style: none;
      display: block;
      margin: 0;
      padding: 0;
      @include body('.shrinked-sidebar'){
        overflow: visible !important;
      }
      > li {
        position: relative;
        width: 100%;
        a {
          color: $text-main;
          display: block;
          font-size: 14px;
          font-weight: 500;
          line-height: 45px;
          padding: 0;
          position: relative;
          border-bottom: 1px solid rgba(238,238,238,.7);
          span{
            @include body('.shrinked-sidebar'){
              display: none;
            }
          }
          .list-icon {
            vertical-align: middle;
            transition: $transition-base;
            display: inline-block;
            color: lighten($text-main, 40%);
            width: 50px;
            text-align: center;
            font-size: 25px;
            flex-shrink: 0;
            @include body('.shrinked-sidebar'){
              margin: auto;
              width: 60px;
              font-size: 24px;
              vertical-align: middle;
              color: $text-main;
            }
          }
          .arrow-down {
            position: absolute;
            top: 50%;
            margin-top: -9px;
            font-size: 18px;
            line-height: 17px;
            transition: $transition-base;
            @include ltr(){
              right: 10px;
            }
            @include rtl(){
              left: 10px;
              right: auto;
            }
            @include body('.shrinked-sidebar'){
              display: none;
            }
          }
        }
        &.open-submenu .arrow-down {
          transform: rotate(180deg);
        }
        .sub-menu-cont {
          display: none;
          position: relative;
          -webkit-transition: max-height 0.8s;
          -moz-transition: max-height 0.8s;
          transition: max-height 0.8s;
          box-shadow: inset 0px 9px 15px -12px rgba(0, 0, 0, 0.4), inset 0px -9px 20px -15px rgba(0, 0, 0, 0.4);
          background: darken($side-menu-bg, 1%);
          @include ltr(){
            left: 0;
          }
          @include rtl(){
            right: 0;
            left: auto;
          }
          &.opens-top{
            bottom: 100%;
          }
          li {
            float: none;
            width: 100%;

            a {
              line-height: 20px;
              min-height: 20px;
              border-bottom: 1px solid rgba(0, 0, 0, 0.05);
              display: flex;
              flex-direction: row-reverse;
              justify-content: flex-end;
              align-items: center;
              &:hover{
                background: rgba(0,0,0,.03);
              }
              @include ltr(){
                font-size: 12px;
                text-align: left;
                padding: 10px 0 10px 20px;
              }
              @include rtl(){
                text-align: right;
                padding: 10px 20px 10px 0;
                font-size: 13px;
              }
              @include body('.shrinked-sidebar'){
                width: 100%;
              }
              @include ltr('.shrinked-sidebar'){
                padding: 10px 0 10px 20px;
              }
              @include rtl('.shrinked-sidebar'){
                padding: 10px 20px 10px 0;
              }
              &:hover{
                @include body('.shrinked-sidebar'){
                  width: 100%;
                  background: rgba(0,0,0,.03);
                  color: #3a3e63 !important;
                }
              }
              i {
                display: inline-flex;
                align-items: center;
                margin: 0;
                color: #a1a5c8;
                font-weight: 500;
                font-style: normal;
                text-indent: -1px;
                font-size: 12px;
                &::before {
                  font-size: 14px;
                  line-height: 10px;
                  margin: 5px 0;
                }
                &::after {
                  font-size: 12px;
                }
                &[class^="icon"] {
                  display: none;
                }
                &.mdi-rocket {
                  &::after {
                    @include ltr() {
                      content: 'Beta';
                    }
                    @include rtl() {
                      content: 'اختباري';
                    }
                  }
                }
                @include ltr(){
                  margin-left: 5px;
                }
                @include rtl(){
                  margin-right: 5px;
                }
              }
            }
          }
        }
        &.current > a,
        > a:hover {
        //&.open-submenu > a {
        //  background: #ffffff;
          color: $text-main !important;
        }
        /* Custom Menu Styles */
        &.mn_organizational,
        &.mn_installment_agreement,
        &.mn_sales-commissions-parent,
        &.mn_rental,
        &.mn_multi-line {
          a.menu-item{
            display: flex;
            align-items: center;
            padding-top: 5px;
            padding-bottom: 5px;
            @include ltr(){
              padding-right: 15px;
              padding-left: 0;
            }
            @include rtl(){
              padding-left: 0;
              padding-right: 0;
            }
            @include body('.shrinked-sidebar'){
              display: block;
            }
            span{
              line-height: 1;
              @include ltr(){
                padding-left: 2px;
              }
              @include rtl(){
                padding-left: 35px;
              }
            }
          }
        }
        &.mn_client_loyalty_points {
          a.menu-item{
            display: flex;
            align-items: center;
            padding-top: 2px;
            padding-bottom: 2px;
            @include ltr(){
              padding-right: 25px;
              padding-left: 0;
            }
            @include rtl(){
              padding-left: 0;
              padding-right: 0;
            }
            @include body('.shrinked-sidebar'){
              display: block;
            }
            span{
              line-height: 1;
              @include ltr(){
                padding-left: 2px;
              }
              @include rtl(){
                padding-left: 35px;
              }
            }
          }
        }
        &:hover {
          > a{
            background: $white;
            @include body('.shrinked-sidebar'){
              background: $text-main;
              color: #fff !important;
              width: 300px;
            }
            span{
              @include body('.shrinked-sidebar'){
                display: inline-block;
              }
              @include ltr('.shrinked-sidebar'){
                margin-left: 15px;
              }
              @include rtl('.shrinked-sidebar'){
                margin-right: 16px;
                margin-left: auto;
              }
            }
            .list-icon {
              @include body('.shrinked-sidebar'){
                color: $white;
              }
            }
          }
          > .sub-menu-cont {
            @include body('.shrinked-sidebar'){
              width: 242px !important;
              display: block !important;
              position: absolute;
              z-index: 100;
              border-top: 0;
              box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
            }
            @include ltr('.shrinked-sidebar'){
              left: 100%;
            }
            @include rtl('.shrinked-sidebar'){
              right: 100%;
              left: auto;
            }
          }
        }
      }
    }
  }
}


//.main-nav li ul li a:hover {
//  background: rgba(0, 0, 0, 0.03);
//}

.menu-overlay {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  background: rgba(255, 255, 255, 0.6);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  z-index: 100;
  transition: $transition-base;
  @include body('.visible-sidbar'){
    visibility: visible;
    opacity: 1;
    left: 210px;
  }
  //@include ltr('.visible-sidbar'){
  //  left: 210px;
  //}
  @include rtl('.visible-sidbar'){
    right: 210px;
  }
}
/*
 * Perfect Scrollbar
 */

//.ps {
//  overflow: hidden !important;
//  overflow-anchor: none;
//  -ms-overflow-style: none;
//  touch-action: auto;
//  -ms-touch-action: auto;
//}
//
///*
// * Scrollbar rail styles
// */
//
//.ps__rail-x {
//  display: none;
//  opacity: 0;
//  transition: background-color 0.2s linear, opacity 0.2s linear;
//  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
//  height: 15px;
//  /* there must be 'bottom' or 'top' for ps__rail-x */
//  bottom: 0px;
//  /* please don't change 'position' */
//  position: absolute;
//}
//
//.ps__rail-y {
//  display: none;
//  opacity: 0;
//  transition: background-color 0.2s linear, opacity 0.2s linear;
//  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
//  width: 15px;
//  /* there must be 'right' or 'left' for ps__rail-y */
//  right: 0;
//  /* please don't change 'position' */
//  position: absolute;
//}
//
//.ps--active-x > .ps__rail-x,
//.ps--active-y > .ps__rail-y {
//  display: block;
//  background-color: transparent;
//}
//
//.ps:hover > {
//  .ps__rail-x,
//  .ps__rail-y {
//    opacity: 0.6;
//  }
//}
//
//.ps--focus > {
//  .ps__rail-x,
//  .ps__rail-y {
//    opacity: 0.6;
//  }
//}
//
//.ps--scrolling-x > .ps__rail-x,
//.ps--scrolling-y > .ps__rail-y {
//  opacity: 0.6;
//}
//
//.ps {
//  .ps__rail-x:hover,
//  .ps__rail-y:hover,
//  .ps__rail-x:focus,
//  .ps__rail-y:focus,
//  .ps__rail-x.ps--clicking,
//  .ps__rail-y.ps--clicking {
//    background-color: #eee;
//    opacity: 0.9;
//  }
//}
//
///*
// * Scrollbar thumb styles
// */
//
//.ps__thumb-x {
//  background-color: #aaa;
//  border-radius: 6px;
//  transition: background-color 0.2s linear, height 0.2s ease-in-out;
//  -webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
//  height: 6px;
//  /* there must be 'bottom' for ps__thumb-x */
//  bottom: 2px;
//  /* please don't change 'position' */
//  position: absolute;
//}
//
//.ps__thumb-y {
//  background-color: #aaa;
//  border-radius: 6px;
//  transition: background-color 0.2s linear, width 0.2s ease-in-out;
//  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
//  width: 6px;
//  /* there must be 'right' for ps__thumb-y */
//  right: 2px;
//  /* please don't change 'position' */
//  position: absolute;
//}
//
//.ps__rail-x {
//  &:hover > .ps__thumb-x,
//  &:focus > .ps__thumb-x,
//  &.ps--clicking .ps__thumb-x {
//    background-color: #999;
//    height: 11px;
//  }
//}
//
//.ps__rail-y {
//  &:hover > .ps__thumb-y,
//  &:focus > .ps__thumb-y,
//  &.ps--clicking .ps__thumb-y {
//    background-color: #999;
//    width: 11px;
//  }
//}
//
///* MS supports */
//@supports (-ms-overflow-style: none) {
//  .ps {
//    overflow: auto !important;
//  }
//}
//
//@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
//  .ps {
//    overflow: auto !important;
//  }
//}
@include media-breakpoint-down(md) {
  .main-nav {
    @include ltr(){
      left: -210px;
    }
    @include rtl(){
      right: -210px;
      left: auto;
    }
  }
  .visible-sidbar {
    .header {
      width: 100%;
    }
  }
  .ltr.visible-sidbar {
    .main-nav {
        left: 0px;
      }
    .header {
      transform: translateX(210px);
        left: 0;
    }
  }
  .rtl.visible-sidbar {
    .main-nav {
      right: 0px;
      left: auto;
    }

    .header {
      transform: translateX(-210px);
      right: 0;
      left: auto;
    }
  }
}


body.android{
  overflow: scroll !important;
  #main-nav  {
    overflow:scroll !important; position: absolute !important; height: auto !important;
  }
  .main-nav .nav-scroll,
  .main-nav .mob-nav {
    height: auto !important;
  }
}
