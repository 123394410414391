@import "../variables";
@import "../mixins";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/functions";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/variables";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/mixins";

.progress-steps{
  overflow: hidden;
  padding: 0 2px;
}

.step {
  position: relative;
  text-align: center;
  width: 33.333%;
  height: 100px;
}

.step .step-text{
  color: $primary;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1;
  height: 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0;
  width: 100%;
}

.step .step-number {
  position:relative;
  justify-content: center;
}

.step .step-number:after {
  content: '';
  display: block;
  clear: both;
}

.step .step-number .number {
  z-index: 2;
  width: 45px;
  height: 45px;
  line-height: 38px;
  /*margin: 0 auto;*/
  background: #fff;
  font-size: 18px;
  font-weight: bold;
  color: $primary;
  vertical-align: middle;
  float: none;
  display: inline-block;
  text-align: center;
  border: 3px solid #ebf2f6;
  border-radius: 50%;
  transform: scale(1.05);
}

.step .step-number .step-line{
  bottom: 16px;
  background: #ebf2f6;
  height: 12px;
  margin-left: -1px;
  position:absolute;
  width: 150%;
  float: none;
  @include ltr(){
    left: 50%;
    right: inherit;
  }
  @include rtl(){
    right: 50%;
    left: inherit;
  }
}

.step.active .step-number .number{
  background: $primary;
  border-color: $primary;
  color: #fff;
  font-size: 0;
  transform: scale(1.1);
}

.step.active .step-number .number:after{
  content: $fa-check;
  font-size: 20px;
  font-family: "Font Awesome 5 Pro";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 40px;
}

.step.active .step-line{
  background: $primary;
}

.step.active + .step .step-number .number {
  border-color: $primary;
}

.step.first .step-number .number {
  border-color: $primary;
}



.step:first-child .step-number{
  justify-content: flex-start;
}

.step:first-child .step-number .step-line{
  @include ltr(){
    left: 0;
  }
  @include rtl(){
    right: 0;
    left: inherit;
  }
}

.step:last-child .step-number{
  justify-content: flex-end;
}


@include media-breakpoint-down(sm) {
  .step .step-number .step-line {
    width: 0;
    display: none;
  }

  .step .step-text {
    width: auto;
    margin-left: 0;
    font-size: 12px;
    min-width: 90px;
    padding: 0 10px;
  }

  .step .step-number .number {
    float: none;
    margin: 0 auto;
  }
}


@include media-breakpoint-up(sm) {
  .step:first-child{
    @include ltr() {
      text-align: left;
    }
    @include rtl(){
      text-align: right;
    }
  }

  .step:last-child{
    @include ltr(){
      text-align: right;
    }
    @include rtl(){
      text-align: left;
    }
  }
}
