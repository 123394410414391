@import "../variables";
@import "../mixins";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/functions";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/variables";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/mixins";

.ribbon {
  width: 100px;
  height: 100px;
  overflow: hidden;
  position: absolute;
  //&::before,
  //&::after {
  //  position: absolute;
  //  z-index: -1;
  //  content: '';
  //  display: block;
  //}
  span {
    position: absolute;
    display: block;
    width: 150px;
    padding: 3px 0;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: $white;
    font-size: 12px;
    font-weight: 600;
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    text-align: center;
  }
  /* top left*/
  &.ribbon-top-left {
    //top: -10px;
    //left: -10px;
    top: 0;
    @include rtl(){
      right: 0;
    }
    @include ltr(){
      left: 0;
    }
  }
  //&.ribbon-top-left::before,
  //&.ribbon-top-left::after {
  //  border-top-color: transparent;
  //  border-left-color: transparent;
  //}
  //&.ribbon-top-left::before {
  //  top: 0;
  //  right: 0;
  //}
  //&.ribbon-top-left::after {
  //  bottom: 0;
  //  left: 0;
  //}
  &.ribbon-top-left span {
    top: 25px;
    @include rtl(){
      left: -15px;
      transform: rotate(45deg);
    }
    @include ltr(){
      right: -15px;
      transform: rotate(-45deg);
    }
  }

  /* top right*/
  &.ribbon-top-right {
    //top: -10px;
    //right: -10px;
    top: 0;
    @include rtl(){
      left: 0;
    }
    @include ltr(){
      right: 0;
    }
  }
  //&.ribbon-top-right::before,
  //&.ribbon-top-right::after {
  //  border-top-color: transparent;
  //  border-right-color: transparent;
  //}
  //&.ribbon-top-right::before {
  //  top: 0;
  //  left: 0;
  //}
  //&.ribbon-top-right::after {
  //  bottom: 0;
  //  right: 0;
  //}
  &.ribbon-top-right span {
    top: 25px;
    @include rtl(){
      right: -15px;
      transform: rotate(-45deg);
    }
    @include ltr(){
      left: -15px;
      transform: rotate(45deg);
    }
  }

  /* bottom left*/
  &.ribbon-bottom-left {
    //bottom: -10px;
    //left: -10px;
    bottom: 0;
    @include rtl(){
      right: 0;
    }
    @include ltr(){
      left: 0;
    }
  }
  //&.ribbon-bottom-left::before,
  //&.ribbon-bottom-left::after {
  //  border-bottom-color: transparent;
  //  border-left-color: transparent;
  //}
  //&.ribbon-bottom-left::before {
  //  bottom: 0;
  //  right: 0;
  //}
  //&.ribbon-bottom-left::after {
  //  top: 0;
  //  left: 0;
  //}
  &.ribbon-bottom-left span {
    bottom: 25px;
    @include rtl(){
      left: -15px;
      transform: rotate(-225deg);
    }
    @include ltr(){
      right: -15px;
      transform: rotate(225deg);
    }
  }

  /* bottom right*/
  &.ribbon-bottom-right {
    //bottom: -10px;
    //right: -10px;
    bottom: 0;
    @include rtl(){
      left: 0;
    }
    @include ltr(){
      right: 0;
    }
  }
  //&.ribbon-bottom-right::before,
  //&.ribbon-bottom-right::after {
  //  border-bottom-color: transparent;
  //  border-right-color: transparent;
  //}
  //&.ribbon-bottom-right::before {
  //  bottom: 0;
  //  left: 0;
  //}
  //&.ribbon-bottom-right::after {
  //  top: 0;
  //  right: 0;
  //}
  &.ribbon-bottom-right span {
    bottom: 25px;
    @include rtl(){
      right: -15px;
      transform: rotate(225deg);
    }
    @include ltr(){
      left: -15px;
      transform: rotate(-225deg);
    }
  }
}
@each $color, $value in $theme-colors {
  .ribbon-#{$color} {
    //&::before,
    //&::after{
    //  border: 5px solid lighten($value, 10%);
    //}
    span{
      background-color: $value;
    }
  }
}
