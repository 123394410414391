@import "../variables";
@import "../mixins";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/functions";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/variables";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/mixins";

.btn-secondary,
.btn-outline-secondary {
  color: $text-main;
}
.btn-dark-blue.focus,
.btn-dark-blue:focus {
  color: #fff;
}

.btn {
  transition: $transition-base;
  &.disabled{
    color: #b7b7b7;
  }
  i {
    vertical-align: middle;
    line-height: .7;
  }
  &:not(.btn-icon) {
    // i {
    //   // font-size: 26px;
    //   // vertical-align: middle;
    //   // line-height: 22px;
    // }
    &.btn-sm {
      i {
        font-size: 20px;
        vertical-align: middle;
        line-height: 10px;
      }
    }
    &.btn-lg {
      i {
        font-size: 28px;
        vertical-align: middle;
        line-height: 10px;
      }
    }
  }


  &.btn-icon {
    position: relative;
    padding: $btn-padding-y $btn-padding-x $btn-padding-y $btn-icon-padding-x;
    i:not(.caret) {
      position: absolute;
      top: -1px;
      left: -1px;
      //background: rgba(0,0,0,.1);
      background: rgba(0, 33, 125, 0.1);
      //width: 38px;
      //height: 38px;
      width: 35px;
      height: 35px;
      display: inline-block;
      vertical-align: middle;
      //line-height: 38px;
      line-height: 35px;
      font-size: 20px;
      // border-radius: $border-radius 0 0 $border-radius;
      &.mdi-export{
        text-indent: 2px;
      }
    }
    &.btn-sm {
      padding: $btn-padding-y-sm $btn-padding-x-sm $btn-padding-y-sm $btn-icon-padding-x-sm;
      i {
        background: rgba(0,0,0,.1);
        width: 31px;
        height: 31px;
        display: inline-block;
        line-height: 31px;
        font-size: 16px;
        // border-radius: $border-radius-sm 0 0 $border-radius-sm;
      }
    }
    &.btn-lg {
      padding: $btn-padding-y-lg $btn-padding-x-lg $btn-padding-y-lg $btn-icon-padding-x-lg;
      i {
        background: rgba(0,0,0,.1);
        width: 48px;
        height: 48px;
        display: inline-block;
        line-height: 48px;
        font-size: 22px;
        // border-radius: $border-radius-lg 0 0 $border-radius-lg;
      }
    }

    &.responsive {
      span {
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
      i:not(.caret) {
        @include media-breakpoint-down(sm) {
          position: relative;
          top: 0;
          left: 0;
          right: 0;
        }
      }
      @include media-breakpoint-down(sm) {
        padding: 0;
        border: 0;
      }
    }
    &.remove-row{
      float: right;
      padding: $btn-padding-y $btn-padding-x $btn-padding-y 2.2rem;
      font-weight: bold;
      color: #555;
      @include media-breakpoint-down(md) {
        width: 35px;
        height: 35px;
        overflow: hidden;
        padding: 0;
        color: transparent;
      }
      i{
        color: $danger;
        background: none;
      }
    }
  }
  &.btn-link {
    color: $text-main;
  }

}
.btn-group {
  .btn-light{
    background: #fff;
    border-color: #dadada;
    color: $inactive;
    i{
      font-size: 16px;
      color: $text-main;
      &.mdi{
        position: relative;
        // top: -1px;
      }
    }
    &:hover,
    &:focus{
      color: #3a3e63;
      background-color: #e4ebf2;
      border-color: #dadada;
    }
  }
   > .btn:not(:first-child),
  > .btn-group:not(:first-child) {
    margin: 0;
    @include rtl(){
      border-left: 1px solid #fff;
    }
     @include rtl(){
       margin-right: -1px;
       margin-left: auto;
       border-right: 1px solid #fff;
       border-left: none;
     }
  }
  // > .dropdown-toggle-split {
  //   padding: .375rem 10px;
  // }
}
.btn-group-sm {
  > .dropdown-toggle-split {
    padding: 0.25rem 0.5rem;
  }
  .custom-control {
    min-height: 21px;
    .custom-control-label::before,
    .custom-control-label::after {
      top: 3px;
    }
  }
}
.btn-light {
  color: $text-main;
  background: #fff;
}

// Custom buttons focus style
.btn-danger:focus,.btn-danger.focus,
.btn-info:focus,.btn-info.focus,
.btn-primary:focus,.btn-primary.focus,
.btn-success:focus,.btn-success.focus,
.btn-dark:focus,.btn-dark.focus{
  color: #fff;
}

.rtl{
  .dropdown-toggle-split {
    padding-left: 15px;
    padding-right: 15px;
    &::after {
      margin-right: 0;
    }
  }
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-right: 0;
  }

  .dropleft .dropdown-toggle-split::before {
    margin-left: 0;
  }
  .btn-sm + .dropdown-toggle-split,
  .btn-group-sm > .btn + .dropdown-toggle-split {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }

  .btn-lg + .dropdown-toggle-split,
  .btn-group-lg > .btn + .dropdown-toggle-split {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}


.rtl{
  .btn {
    font-weight: bold;
    &.btn-icon {
      padding: $btn-padding-y $btn-icon-padding-x $btn-padding-y $btn-padding-x;
      &.remove-row{
        padding: $btn-padding-y 2.2rem $btn-padding-y $btn-padding-x;
      }
      i:not(.caret) {
        &.mdi-export{
          text-indent: -2px;
        }
        top: -1px;
        left: auto;
        right: -1px;
        //border-radius: 0 $border-radius $border-radius 0;
      }
      &.btn-sm {
        padding: $btn-padding-y-sm $btn-icon-padding-x-sm $btn-padding-y-sm $btn-padding-x-sm;
      }
      &.btn-lg {
        padding: $btn-padding-y-lg $btn-icon-padding-x-lg $btn-padding-y-lg $btn-padding-x-lg;
      }
      &.responsive {
        @include media-breakpoint-down(sm) {
          padding: 0;
          border: 0;
        }
      }
    }
  }
}
