@import "../variables";
@import "../mixins";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/functions";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/variables";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/mixins";

.panel {
  .panel-head {
    background: $side-menu-bg;
    color: $text-main;
    font-size: 16px;
    padding: 10px 15px;
    &.panel-head-input {
      padding: 7px 15px;
      label {
        display: block;
        cursor: pointer;
      }
    }
    h3 {
      font-size: 14px;
      margin: 0;
      color: $inactive;
      font-weight: 600;
    }
  }
  .panel-content{
    padding: 10px 30px;
  }
}
