@import "../../../node_modules/bootstrap-v4-rtl/scss/functions";
@import "../../../node_modules/bootstrap-v4-rtl/scss/variables";
@import "../../../node_modules/bootstrap-v4-rtl/scss/mixins";

/****************************
 ****      Colors
****************************/

$primary: #00B0EF;
//$secondary: #E4EBF2;
$secondary: darken(#F6F9FC, 7%);
$success: #13B272;
$info: #01bfa5;
$warning: #ff9910;
$danger: #FC5F7D;
$light: #F6F9FC;
$dark: #485563;
$inactive: #75799D;
$input: #ced4da;
$disabled: #e9ecef;
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;
//$body-bg: #E4EBF2;
$body-bg: #E4EBF2;
//$content-bg: #E4EBF2;
$main-bg: #4e5381;
$dark-blue: #4e5381;
$side-menu-bg: #f6f9fc;
//$text-main: #4e5381;
$text-main: #3a3e63;
$text-main-light: lighten($text-main, 40%);
$text-muted: lighten($text-main, 20%);

$theme-colors: (
  "blue": #007bff,
  "indigo": #6610f2,
  "purple": #6f42c1,
  "pink": #e83e8c,
  "red": #FC5F7D,
  "orange": #f47c0b,
  "yellow": #ffc107,
  "green": #28a745,
  "teal": #20c997,
  "cyan": #17a2b8,
  "white": #fff,
  "gray": #6c757d,
  "gray-light": #9B9EB8,
  "gray-dark": #485563,
  "primary": #00B0EF,
  "sky": #CBEFFC,
  "main": #3a3e63,
  "main-light": lighten(#3a3e63, 40%),
  //"secondary": #E4EBF2,
  "secondary": darken(#F6F9FC, 7%),
  "success": #13B272,
  "info": #01bfa5,
  "warning": #ff9910,
  "danger": #FC5F7D,
  "light": #F6F9FC,
  "dark": #485563,
  "dark-blue": #4E5381,
  "active": #80C342,
  "inactive": #75799D,
  "input": #ced4da,
  "disabled": #e9ecef,
);

/****************************
 ****      Breakpoints
****************************/

$xs: 319px;
$sm: 575px;
$md: 767px;
$lg: 991px;
$xl: 1200px;
$xxl: 1600px;
$xxxl: 2100px;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1800px,
  xxxl: 2200px
);

/****************************
 ****      Containers
****************************/

$container-max-widths: (
  sm: 940px,
  md: 950px,
  lg: 960px,
  xl: 1140px
);

/****************************
 ****      Icons
****************************/

$mdi-eye: "\F0208";
$mdi-circle-edit-outline: "\F08D5";
$mdi-trash-can-outline: "\F0A7A";
$mdi-page-next-outline: "\F0BB1";
$mdi-lock-reset: "\F0773";
$mdi-account-remove: "\F0015";
$mdi-close-circle-outline: "\F015A";
$mdi-flash-red-eye: "\F067B";
$mdi-package-down: "\F03D4";
$mdi-bookmark-plus: "\F00C5";
$mdi-lock: "\F033E";
$mdi-print: "\F042A";
$mdi-lock-open-variant: "\F0FC6";
$mdi-information: "\F02FC";
$mdi-chevron-right: "\F0142";
$mdi-chevron-left: "\F0141";
$fa-check: "\f00c";
$fa-sort: "\F00DC";
$fa-sort-up: "\F00DE";
$fa-sort-down: "\F00DD";
$fa-spinner-third: "\f3f4";
$fa-folders: "\f660";

/****************************
 ****      Font Sizes & Line Heights
****************************/

$font_sizes: (
  10: 10px,
);

$line_height_sizes: (
  1: 1,
);

/****************************
 ****      Spacings
****************************/

$spacer: 5px;
$spacers: ();
$spacers: map-merge((
  0: 0,
  1: $spacer,
  2: ($spacer * 2),
  3: ($spacer * 3),
  4: ($spacer * 4),
  5: ($spacer * 5),
  6: ($spacer * 6),
  7: ($spacer * 7),
  8: ($spacer * 8),
  9: ($spacer * 9),
  10: ($spacer * 10),
  11: ($spacer * 11),
  12: ($spacer * 12),
  13: ($spacer * 13),
  14: ($spacer * 14),
  15: ($spacer * 15),
), $spacers);

/****************************
 ****      Sizes
****************************/

$sizes: ();
$sizes: map-merge((
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%,
  20px: 20px,
  30px: 30px,
  32px: 32px,
  48px: 48px,
  120px: 120px,
  175px: 175px,
  auto: auto,
), $sizes);

/****************************
 ****      General
****************************/

$grid-gutter-width: 20px;
$transition-base:all .2s ease-in-out;
$border-color: $gray-300;
$enable-rounded:false;
$border-radius: 0;
$enable-print-styles: true;
$print-page-size: a4;
$font-size-base: 0.875rem;

/****************************
 ****      Buttons
****************************/

$btn-padding-x:.875rem;
$btn-padding-y:.375rem;
$btn-padding-x-lg :1rem;
$btn-padding-y-lg :.5rem;
$btn-padding-x-sm :.5rem;
$btn-padding-y-sm :.25rem;
$btn-icon-padding-x: $btn-padding-x * 3.4;
$btn-icon-padding-x-lg: $btn-padding-x-lg * 4;
$btn-icon-padding-x-sm: $btn-padding-x-sm * 5;
$btn-font-size: 0.875rem;
//btn-icon
//$btn-icon-padding-x: $btn-padding-x * 4;

/****************************
 ****      Forms
****************************/

$form-grid-gutter-width: 15px;
$input-font-size: 0.875rem;
$input-placeholder-color: lighten($text-main, 30%);
$custom-select-font-size: 0.875rem;
$input-height: calc(#{$input-line-height * 1em} + #{$input-padding-y * 2} + #{$input-height-border + 3});
$custom-select-height: calc(#{$input-line-height * 1em} + #{$input-padding-y * 2} + #{$input-height-border + 3});

/****************************
 ****      Tabs
****************************/

$nav-tabs-link-active-border-color: $gray-300 $gray-300 $white ;

// Switches
$custom-switch-gutter: $custom-control-gutter;
$custom-switch-indicator-border-color: #fff;
$custom-switch-indicator-bg: $inactive;
$custom-switch-indicator-checked-color: $inactive;
$custom-switch-indicator-checked-border-color: $success;
$custom-switch-indicator-checked-bg: $success;
$custom-switch-indicator-checked-box-shadow: $success;
$custom-switch-indicator-size: 0.8rem;
$custom-switch-width: 2.6rem;
$custom-switch-indicator-checked-disabled-bg: $custom-control-indicator-checked-disabled-bg;
// $custom-switch-indicator-size: 12px;
$custom-switch-indicator-border-radius: 3rem;
$custom-switch-indicator-border-width: .2rem;
$custom-switch-indicator-active-color: $inactive;
$custom-switch-indicator-active-bg: $inactive;
$custom-switch-indicator-active-border-color: $inactive;
$custom-switch-indicator-active-box-shadow: $inactive;
$custom-switch-indicator-focus-border-color: $inactive;
