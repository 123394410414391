@import "../variables";
@import "../mixins";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/functions";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/variables";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/mixins";

.fixed-toast{
  position: fixed;
  z-index: 99999;
  bottom: 0;
  transition: 0.3s all;
  color: $white;
  background: $inactive;
  border-radius: $border-radius;
  padding: 10px 15px;
  min-width: 300px;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include ltr(){
    right: 15px;
  }
  @include rtl(){
    left: 15px;
  }
  &.active{
    bottom: 15px;
  }
  @include media-max(md) {
    left: 0 !important;
    bottom: -500px;
    border-radius: 0 !important;
    max-width: 100%;
    min-width: 100%;
    &.active{
      bottom: 0;
    }
  }
  p{
    margin-bottom: 0;
    &::first-letter{
      text-transform: capitalize;
    }
  }
  a {
    color: $white;
    text-decoration: underline;
    &:active,
    &:focus,
    &:hover {
      color: $white;
      text-decoration: underline;
    }
  }
  .pointer{
    &:hover{
      .text-black-50{
        color: $gray-900 !important;
      }
    }
  }
}
