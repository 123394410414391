@import "../../variables";
@import "../../../../../node_modules/bootstrap-v4-rtl/scss/functions";
@import "../../../../../node_modules/bootstrap-v4-rtl/scss/variables";

.select2-container--bootstrap4 {
    .select2-selection--multiple {

        // height of input box
        min-height: $input-height !important;

        // input box
        .select2-selection__rendered {
            box-sizing: border-box;
            list-style: none;
            margin: 0;
            padding: 0 5px !important;
            width: 100%;
            display: inline-flex;
            flex-wrap: wrap;
            align-items: flex-start;
        }
        .select2-selection__clear {
            float: none;
            position: absolute;
            display: flex;
            justify-items: center;
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            margin-right: 10px;
        }
        // each selected item in input box
        .select2-selection__choice {
            color: $dark;
            border: 1px solid darken($light, 20%);
            border-radius: $btn-border-radius-sm;
            //padding: 0;
            background: $secondary;
            //padding-right: 5px;
            //font-size: 15px;
            cursor: pointer;
            //float: left;
            margin-top: 0.3em;
            margin-right: 5px;
            padding-left: 8px;
            line-height: 15px;
            font-size: 12px;
            max-width: 45%;
            overflow: hidden;
            text-overflow: ellipsis;
            img{
                width: 15px;
                height: 15px;
                transform: scale(1.3);
            }
        }

        // x button of each selected item in input box
        .select2-selection__choice__remove {
            //color: darken($light, 20%);
            font-weight: bold;
            //margin-left: 3px;
            //margin-right: 1px;
            //padding-right: 3px;
            //padding-left: 3px;
            //float: left;
            float: right;
            margin-left: 5px;
            margin-right: 5px;
            line-height: 14px;
            font-size: 14px;
            position: relative;
            top: 0;
            //color: darken($danger, 20%);
            border: 1px solid $secondary;
            border-radius: 50%;
            background-color: $secondary;
            box-shadow: 0 0 0 3px $secondary;
            width: 16px;
            height: 16px;
            color: $inactive;
            padding: 0;
            text-align: center;

            &:hover {
                //color: $dark;
                //color: #ffffff;
                //background-color: darken($danger, 20%);
                //border: 1px solid darken($danger, 20%);
                border: 1px solid $dark;
                background-color: $dark;
                color: $light;
            }
        }
        .select2-search__field{
            &::placeholder {
                color: #8186b5;
                opacity: 1;
            }
        }
    }
}
