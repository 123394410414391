@import "../variables";
@import "../mixins";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/functions";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/variables";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/mixins";


/****************************
 ****      Main
****************************/

html {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 16px;
}
body {
  background: $body-bg;
  font-weight: 400;
  font-size: 14px;
  color: $text-main;
  font-family: 'Roboto', 'Segoe UI' ,Helvetica, Tahoma,'Open Sans', arial, serif;
  position: relative;
  min-height: 100vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 1;
  flex: 1;
  line-height: 1.6;
}

input,
select{
  font-weight: 500;
}

.rtl{
  direction: rtl;
  text-align: right;
  input{
    text-align: right;
  }
}

.ltr{
  direction: ltr;
  text-align: left;
  input{
    text-align: left;
  }
}

.ltr-input{
  direction: ltr;
  text-align: left;
  input{
    text-align: left;
  }
  .invalid-message{
    text-align: right;
  }
  &.ltr-input-reverse{
    text-align: inherit;
  }
}

.pre{
  white-space: pre-wrap;
}

.opacity-100{
  opacity: 1;
}

.opacity-75{
  opacity: .75;
}

.opacity-50{
  opacity: .5;
}

.opacity-25{
  opacity: .25;
}

.opacity-0{
  opacity: 0;
}

.border-w-2{
  border-width: 2px !important;
}

.rounded-10px{
  border-radius: 10px;
}

.font-weight-medium {
  font-weight: 500!important;
}

.text-main{
  color: $text-main;
}

.text-main-light{
  color: $text-main-light;
}

.bg-main {
  background: $main-bg;
}
.main-area {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 1;
  flex: 1;
  min-height: 90vh;
  transition: $transition-base;
}


// Is Loading
.is-loading{
  position: relative;
  overflow: hidden;
  &:before{
    content: '';
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    background: rgba(228, 235, 242, 1);
    z-index: 99;
  }
  &:after{
    content: $fa-spinner-third;
    font-family: "Font Awesome 5 Pro";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    font-size: 54px;
    -webkit-animation: fa-spin 2s linear infinite;
    animation: fa-spin 2s linear infinite;
  }
}

a {
  color: $text-main;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
    color: darken($text-main, 10%);
  }
}

.text-decoration-underline{
  text-decoration: underline;
}

#main-content {
  transition: $transition-base;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding-left: 210px;
  max-width: 100%;
}
body.body-full-height #main-content {
  display: flex;
}
.shrinked-sidebar {
  #main-content {
    padding-left: 0;
    max-width: calc(100% - 60px);
  }
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.notifcation-list {
  max-height: 300px;
  min-width: 260px;
  margin: 0;
  position: relative;
  border-radius: 0 !important;
}

.mob-nav-trigger {
  color: #ffffff;
  &:hover,
  &:focus {
    color: #ffffff;
  }
}

// big number
.big-number{
  text-align: center;
  z-index: 2;
  width: 70px;
  height: 70px;
  line-height: 70px;
  vertical-align: middle;
  //background-color: #ebf2f6;
  border-radius: 50%;
  font-size: 22px;
  font-weight: bold;
}

.text-big-number{
  font-size: 20px;
  font-weight: bold;
  &.form-control{
    min-height: 56px;
    &::placeholder{
      font-weight: normal;
    }
  }
}

//.big-number.badge-success{
//  background-color: #13b272;
//}
//
//.big-number.badge-danger{
//  background-color: #b30000;
//}
//
//.big-number.badge-primary{
//  background-color: #0059a4;
//}

// Horizontal Lines
hr.spacer{
  margin-top: 30px;
  margin-bottom: 30px;
  &.spacer-sm{
    margin-top: 10px;
    margin-bottom: 10px;
  }
  &.vertical{
    border-left: 1px solid rgba(0,0,0,.1);
    border-top: 0;
    height: 100%;
    position: relative;
  }
  &.dots{
    &:before,
    &:after{
      content: '';
      width: 11px;
      height: 11px;
      border-radius: 50%;
      left: -6px;
      position: absolute;
      background: #e5e5e5;
    }
    &:before{
      top: 0;
    }
    &:after{
      bottom: 0;
    }
  }
  &.dashed{
    border-style: dashed;
  }
}

//card content
.card {
  background-color: #fff;
  border: 1px solid #dfe3e6;
  @include border-radius($border-radius , 0);

  .card-header {
    &.bg-faded {
      background: $body-bg;
    }
    background: #F6F9FC;
    border: none;
    padding-top: 10px;
    padding-bottom: 10px;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
      padding: 0;
      color: lighten($text-main, 28%);
    }
  }
}
@include media-breakpoint-up(md) {
  .page-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
}
@include media-breakpoint-down(md) {
  #main-content {
    padding: 0 !important;
  }
}

// User avatars
.avatar{
  .avatar-img{
    text-align: center;
    width: 90px;
    height: 90px;
    margin: 0 auto;
    border-radius: 50%;
    position: relative;
    &:before{
      content: "\f2bd";
      font-family: Font Awesome\ 5 Pro;
      font-weight: bold;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
      position: absolute;
      top: 0;
      left: 0;
      font-size: 90px;
      color: #d4d4d4;
    }
    img{
      max-width: 100%;
      width: 90px;
      height: 90px;
      margin: 0 auto;
      position: relative;
      z-index: 55;
      border-radius: 50%;
      background: #f0f0f0;
    }
  }
  .file-input-drop{
    min-height: 90px;
  }
}
@include media-breakpoint-down(sm) {
  .avatar{
    .avatar-img{
      margin-bottom: 15px;
    }
  }
}

// Listing avatars
.avatar-wrap{
  text-align: center;
}
.avatar-sm{
  width: 35px;
  height: 35px;
  border-radius: 2px;
}
.avatar-xxs{
  width: 20px;
  height: 20px;
  border-radius: 2px;
}
.avatar-xs{
  width: 25px;
  height: 25px;
  border-radius: 2px;
}
.avatar-lg{
  width: 120px;
  height: 120px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0;
  object-fit: cover;
}

// Loading Partial
.loading-partial{
  display: flex;
}

// Iframes
iframe {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: auto;
  border: none;
}

// Page Loader
.page-loader{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(228, 235, 242, 0.85);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  .spinner-wrap{
    text-align: center;
    .spinner-border{
      width: 4rem;
      height: 4rem;
    }
    .spinner-text{
      margin-top: 15px;
      font-weight: bold;
      font-size: 1.25rem;
      padding: 15px;
    }
  }
}


// Font sizes
$fsizes: (
        fs-12: 12px,
        fs-14: 14px,
        fs-16: 16px,
        fs-18: 18px,
        fs-20: 20px,
        fs-22: 22px,
        fs-24: 24px,
        fs-36: 36px,
        fs-40: 40px,
);

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $fs_label, $fs_size in $fsizes {
      .#{$fs_label}#{$infix}{
        font-size: $fs_size !important;
      }
    }
  }
}

// Line Heights
$lhsizes: (
        lh-1: 1,
        lh-14: 14px,
        lh-16: 16px,
        lh-20: 20px,
        lh-25: 25px,
);

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $lh_label, $lh_size in $lhsizes {
      .#{$lh_label}#{$infix}{
        line-height: $lh_size !important;
      }
    }
  }
}

.font-weight-medium {
  font-weight: 500!important;
}

.font-weight-semibold{
  font-weight: 600 !important;
}

.font-weight-extrabold{
  font-weight: 800 !important;
}

.separator-line{
  background: $input;
  width: 1px;
  height: 30px;
  display: inline-block;
  &.separator-line-xl{
    height: 80px;
  }
}

.card{
  &.card-list{
    background: none;
    border-color: transparent;
    .card-header{
      border-color: transparent;
    }
    .nav-tabs{
      .nav-link{
        background: #F6F9FC;
        &.active{
          background: #fff;
        }
      }
    }
  }
}

.w-75px{
  width: 75px;
}

.h-75px{
  height: 75px;
}

.w-100px{
  width: 100px;
}

.h-100px{
  height: 100px;
}

.img-cover{
  object-fit: cover;
  background-size: cover;
}

// Circle Progress Bar
.circle-progressbar{
  position: relative;
  > canvas{
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: auto !important;
  }
  .circle-progressbar-caption-wrap{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}




.rtl,
[dir="rtl"] {
  text-align: right;
  direction: rtl;

  .nav {
    padding-right: 0;
  }

  .navbar-nav .nav-item {
    float: right;
  }

  .navbar-nav .nav-item + .nav-item {
    margin-right: inherit;
    margin-left: 1rem;
  }

  th {
    text-align: right;
  }

  .alert-dismissible {
    padding-right: 1.25rem;
    padding-left: 4rem;
  }

  .dropdown-menu {
    //right: 0;
    //left: inherit;
    text-align: right;
  }

  .checkbox label {
    padding-right: 1.25rem;
    padding-left: inherit;
  }

  .btn-group > .btn:not(:first-child),
  .btn-group > .btn-group:not(:first-child) {
    margin-left: initial;
    margin-right: -$btn-border-width;
  }

  .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-radius: 0 $border-radius $border-radius 0;
  }

  .btn-group > .btn:last-child:not(:first-child),
  .btn-group > .dropdown-toggle:not(:first-child) {
    border-radius: $border-radius 0 0 $border-radius;
  }

  .btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
    border-radius: $border-radius 0 0 $border-radius;
  }

  // .custom-control {
  //   padding-right: 1.5rem;
  //   padding-left: inherit;
  //   margin-right: inherit;
  //   margin-left: 1rem;
  // }

  // .custom-control-indicator {
  //   right: 0;
  //   left: inherit;
  // }

  .custom-file-label::after {
    right: initial;
    left: -1px;
    border-radius: .25rem 0 0 .25rem;
  }

  // .custom-control-label::after,
  // .custom-control-label::before {
  //   right: -1.5rem;
  //   left: inherit;
  // }

  .custom-select {
    padding: $custom-select-padding-y $custom-select-padding-x $custom-select-padding-y ($custom-select-padding-x + $custom-select-indicator-padding);
    background: $custom-select-bg $custom-select-indicator no-repeat left $custom-select-padding-x center;
    background-size: $custom-select-bg-size;
  }

  // .custom-switch {
  //   padding-right: $custom-switch-width + $custom-control-gutter;
  //   padding-left: inherit;

  //   .custom-control-label {
  //     &::before {
  //       right: -($custom-switch-width + $custom-control-gutter);
  //     }

  //     &::after {
  //       right: calc(#{-($custom-switch-width + $custom-control-gutter)} + #{$custom-control-indicator-border-width * 2});
  //     }
  //   }

  //   .custom-control-input:checked~.custom-control-label {
  //     &::after {
  //       transform: translateX(- $custom-switch-width + $custom-control-indicator-size);
  //     }
  //   }
  // }

  .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
  .input-group > .input-group-append:last-child > .input-group-text:not(:last-child),
  .input-group > .input-group-append:not(:last-child) > .btn,
  .input-group > .input-group-append:not(:last-child) > .input-group-text,
  .input-group > .input-group-prepend > .btn,
  .input-group > .input-group-prepend > .input-group-text {
    border-radius: 0 $border-radius $border-radius 0;
  }

  .input-group > .input-group-append > .btn,
  .input-group > .input-group-append > .input-group-text,
  .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
  .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child),
  .input-group > .input-group-prepend:not(:first-child) > .btn,
  .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
    border-radius: $border-radius 0 0 $border-radius;
  }

  .input-group > .custom-select:not(:first-child),
  .input-group > .form-control:not(:first-child) {
    border-radius: $border-radius 0 0 $border-radius;
  }

  .input-group > .custom-select:not(:last-child),
  .input-group > .form-control:not(:last-child) {
    border-radius: 0 $border-radius $border-radius 0;
  }

  .input-group > .custom-select:not(:last-child):not(:first-child),
  .input-group > .form-control:not(:last-child):not(:first-child) {
    border-radius: 0;
  }

  .radio input,
  .radio-inline,
  .checkbox input,
  .checkbox-inline input {
    margin-right: -1.25rem;
    margin-left: inherit;
  }

  .breadcrumb-item {
    + .breadcrumb-item {
      padding-right: $breadcrumb-item-padding;
      padding-left: 0;

      &::before {
        padding-right: 0;
        padding-left: $breadcrumb-item-padding;
      }

      color: $breadcrumb-divider-color;
      content: $breadcrumb-divider;
    }
  }

  .list-group {
    padding-right: 0;
  }

  .close {
    float: left;
  }

  .modal-header .close {
    margin: -15px auto -15px -15px;
  }

  .modal-footer {
    > :not(:first-child) {
      margin-right: .25rem;
    }

    > :not(:last-child) {
      margin-left: .25rem;
    }

    > :first-child {
      margin-right: 0;
    }

    > :last-child {
      margin-left: 0;
    }
  }

  .alert-dismissible .close {
    right: inherit;
    left: 0;
  }

  .dropdown-toggle::after {
    margin-right: .255em;
    margin-left: 0;
  }

  .form-check-input {
    margin-right: -1.25rem;
    margin-left: inherit;
  }

  .form-check-label {
    padding-right: 1.25rem;
    padding-left: inherit;
  }

  .pagination,
  .list-unstyled,
  .list-inline {
    padding-right: 0;
    padding-left: inherit;
  }

  .pagination{
    .page-item {
      &:first-child {
        .page-link {
          @include border-right-radius($border-radius);
          @include border-left-radius(0);
        }
      }

      &:last-child {
        .page-link {
          @include border-right-radius(0);
          @include border-left-radius($border-radius);
        }
      }
    }
  }
}


.height-auto{
  height: auto;
}


body.rtl {
  direction: rtl;
  text-align: right;
  font-size: 14px;
}

.rtl{

  #main-content {
    padding-right: 210px;
    padding-left: 0;
  }

  &.shrinked-sidebar #main-content {
    padding-right: 0;
  }

  .form-control {
    font-size: 14px;
    min-height: 38px;
    padding: 0.25rem .75rem .375rem .75rem;
  }
  .custom-select{
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='1024' height='640'><path d='M1017 68L541 626q-11 12-26 12t-26-12L13 68Q-3 49 6 24.5T39 0h952q24 0 33 24.5t-7 43.5z'></path></svg>") no-repeat left .75rem center/8px 6px !important;
  }
  // .custom-control {
  //   padding-left: 0;
  //   padding-right: 1.5rem;
  // }
  // .custom-control-label:after,
  // .custom-control-label:before {
  //   left: auto;
  //   right: -1.5rem;
  //   display: block;
  //   width: 1rem;
  //   height: 1rem;
  //   content: "";
  // }
  .help-block ol {
    padding-left: 0;
    padding-right: 15px;
  }
  .list-inline,
  .list-unstyled {
    padding-right: 0;
  }
  .caret {
    margin-right: 2px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
  }


}


.rtl{
  .fa-chevron-right:before {
    content: "\f053" !important;
  }
  .fa-chevron-left:before {
    content: "\f054" !important;
  }
  .fa-arrow-circle-left:before {
    content: "\f0a9" !important;
  }
  .fa-arrow-square-right:before {
    content: "\f33a" !important;
  }
  .fa-arrow-left:before {
    content: "\f061";
  }
  .fa-arrow-right:before {
    content: "\f060";
  }
  .fa-chevron-left:before {
    content: "\f054";
  }
  .fa-angle-left:before {
    content: "\f105";
  }
  .fa-angle-right:before {
    content: "\f104";
  }
  .dropdown-item i {
  }
  .mdi-chevron-right:before {
    content: "\F0141";
  }
  .mdi-chevron-left:before {
    content: "\F0142";
  }
  .fa-level-up-alt:before {
    content: "\f3be";
  }
}
