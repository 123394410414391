@import "../variables";
@import "../mixins";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/functions";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/variables";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/mixins";

h1,.h1,
h2,.h2,
h3,.h3{
  font-weight: 800;
}

h4,.h4,
h5,.h5,
h6,.h6{
  font-weight: bold;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $fs_label, $fs_size in $font_sizes {
      .fs#{$infix}-#{$fs_label}{
        font-size: $fs_size !important;
      }
    }
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $lh_label, $lh_size in $line_height_sizes {
      .lh#{$infix}-#{$lh_label}{
        line-height: $lh_size !important;
      }
    }
  }
}
