@import "../variables";
@import "../mixins";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/functions";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/variables";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/mixins";

.nav-tabs {
  @include rtl(){
    padding: 0;
  }
  .nav-link.active,
  .nav-item.show .nav-link {
    color: $text-main;
    background-color: #fff;
    border-color: $nav-tabs-link-active-border-color;
  }
}
.tab-content {
  > .tab-pane {
    border: 1px solid $gray-300;
    border-top: none;
  }
}
