.SumoSelect {
  p {
    margin: 0;
  }
  width: 100%;
  display: block;
  position: relative;
  outline: none;
}

//.has-error{
//  .SumoSelect{
//    .CaptionCont{
//      border-color: red;
//      box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
//    }
//  }
//}

.SelectBox {
  padding: 5px 8px;
}

.sumoStopScroll {
  overflow: hidden;
}

/* Filtering style */

.SumoSelect {
  .hidden {
    display: none;
  }
  .search-txt {
    display: none;
    outline: none;
  }
  .no-match {
    display: none;
    padding: 6px;
  }
  &.open {
    .search-txt {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      margin: 0;
      padding: .375rem .75rem;
      padding-left: 12px;
      padding-right: 30px;
      border: none;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      &:focus,
      &:active{
        outline: none;
        box-shadow: none;
        border: none;
      }
      &::placeholder{
        color: #8186b5;
      }
    }
    > .search > {
      span, label {
        visibility: hidden;
      }
    }
  }
}

/*this is applied on that hidden select. DO NOT USE display:none; or visiblity:hidden; and Do not override any of these properties. */
.SelectClass, .SumoUnder.custom-select{
  display: inherit;
}
.SelectClass, .SumoUnder {
  position: absolute;
  top: -7000px;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity = 0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
}

.SelectClass {
  z-index: 1;
}

.SumoSelect {
  > {
    .optWrapper > .options li.opt label, .CaptionCont {
      user-select: none;
      -o-user-select: none;
      -moz-user-select: none;
      -khtml-user-select: none;
      -webkit-user-select: none;
    }
  }
  .select-all > label {
    user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
  }
  &:focus > .CaptionCont{
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
    border-color: #01bfa5;
  }
  &.open {
    > .CaptionCont {
      box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
      border-color: #01bfa5;
    }
    > .optWrapper {
      top: 35px;
      display: block;
      &.up {
        top: auto;
        bottom: 100%;
        margin-bottom: 5px;
      }
    }
  }
  > {
    .CaptionCont {
      position: relative;
      border: 1px solid #ced4da;
      min-height: 14px;
      background-color: #fff;
      margin: 0;
      padding: .375rem .75rem;
      padding-left: 12px; padding-right: 30px;
      > {
        span {
          display: block;
          padding-right: 18px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          cursor: default;
          &.placeholder {
            color: #8186b5;
          }
        }
        label {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 30px;
          > i {
            background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='iso-8859-1'?%3E%3C!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 386.257 386.257' style='enable-background:new 0 0 386.257 386.257;' xml:space='preserve'%3E%3Cpolygon points='0,96.879 193.129,289.379 386.257,96.879 '/%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E");
            background-position: center center;
          //url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right .75rem center/8px 10px
            width: 10px;
            height: 10px;
            display: block;
            position: absolute;
            top: 5px;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            background-repeat: no-repeat;
            opacity: 0.8;
          }
        }
      }
    }
    .optWrapper {
      display: none;
      z-index: 1000;
      top: 30px;
      min-width: 100%;
      position: absolute;
      left: 0;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      background: #fff;
      border: 1px solid #ddd;
      box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.11);
      border-radius: 3px;
      overflow: hidden;
    }
  }
  > .optWrapper {
    ul {
      list-style: none;
      display: block;
      padding: 0;
      margin: 0;
      overflow: auto;
    }
    > .options {
      border-radius: 2px;
      position: relative;
      /*Set the height of pop up here (only for desktop mode)*/
      max-height: 250px;
      /*height*/
    }
    &.okCancelInMulti > .options {
      border-radius: 2px 2px 0 0;
    }
    &.selall {
      > .options {
        border-radius: 0 0 2px 2px;
      }
      &.okCancelInMulti > .options {
        border-radius: 0;
      }
    }
    > .options li {
      &.group.disabled > label {
        opacity: 0.5;
      }
      ul li.opt {
        padding-left: 22px;
      }
    }
    &.multiple > .options li ul li.opt {
      padding-left: 50px;
    }
    &.isFloating > .options {
      max-height: 100%;
      //box-shadow: 0 0 100px #595959;
    }
    > .options {
      li.opt {
        padding: 6px 6px;
        position: relative;
        border-bottom: 1px solid #f5f5f5;
      }
      > li.opt:first-child {
        border-radius: 2px 2px 0 0;
      }
    }
    &.selall > .options > li.opt:first-child {
      border-radius: 0;
    }
    > .options > li.opt:last-child {
      border-radius: 0 0 2px 2px;
      //border-bottom: none;
    }
    &.okCancelInMulti > .options > li.opt:last-child {
      border-radius: 0;
    }
    > .options li.opt {
      &:hover {
        background-color: #00b0ef;
        color: #fff;
      }
      &.sel {
        background-color: #a1c0e4;
      }
    }
  }
  .select-all {
    &.sel {
      background-color: #a1c0e4;
    }
    > span {
      position: absolute;
      display: block;
      width: 30px;
      top: 0;
      bottom: 0;
      margin-left: -35px;
    }
    > span i {
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 14px;
      height: 14px;
      border: 1px solid #AEAEAE;
      border-radius: 2px;
      box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15);
      background-color: #fff;
    }
    &.selected > span i, &.partial > span i {
      background-color: rgb(17, 169, 17);
      box-shadow: none;
      border-color: transparent;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAGCAYAAAD+Bd/7AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNXG14zYAAABMSURBVAiZfc0xDkAAFIPhd2Kr1WRjcAExuIgzGUTIZ/AkImjSofnbNBAfHvzAHjOKNzhiQ42IDFXCDivaaxAJd0xYshT3QqBxqnxeHvhunpu23xnmAAAAAElFTkSuQmCC');
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
  > .optWrapper {
    > .options li {
      label {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: block;
        cursor: pointer;
        font-size: 14px;
      }
      span {
        display: none;
      }
      &.group > label {
        cursor: default;
        padding: 8px 6px;
        font-weight: bold;
      }
    }
    &.isFloating {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      width: 90%;
      bottom: 0;
      margin: auto;
      max-height: 90%;
      top: 45px;
      margin: 0;
      padding: 0;
      min-height: calc(100vh - 100px);
    }
    > .options li.opt.disabled {
      background-color: inherit;
      pointer-events: none;
      * {
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
        /* IE 5-7 */
        filter: alpha(opacity = 50);
        /* Netscape */
        -moz-opacity: 0.5;
        /* Safari 1.x */
        -khtml-opacity: 0.5;
        /* Good browsers */
        opacity: 0.5;
      }
    }
    &.multiple > .options li.opt {
      padding-left: 35px;
      cursor: pointer;
      span {
        position: absolute;
        display: block;
        width: 30px;
        top: 0;
        bottom: 0;
        margin-left: -35px;
      }
    }
  }
  > .optWrapper.multiple > .options li.opt span i {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: -3px;
    bottom: 0;
    width: 16px;
    height: 16px;
    border: 1px solid #AEAEAE;
    border-radius: 2px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15);
    background-color: #fff;
  }
  > .optWrapper {
    > .MultiControls {
      display: none;
      border-top: 1px solid #ddd;
      background-color: #fff;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.13);
      border-radius: 0 0 3px 3px;
    }
    &.multiple {
      &.isFloating > .MultiControls {
        display: block;
        margin-top: 5px;
        position: absolute;
        bottom: 0;
        width: 100%;
      }
      &.okCancelInMulti > .MultiControls {
        display: block;
        > p {
          padding: 6px;
          &:focus {
            box-shadow: 0 0 2px #a1c0e4;
            border-color: #a1c0e4;
            outline: none;
            background-color: #a1c0e4;
          }
        }
      }
      > .MultiControls > p {
        display: inline-block;
        cursor: pointer;
        padding: 12px;
        width: 50%;
        box-sizing: border-box;
        text-align: center;
        &:hover {
          background-color: #f1f1f1;
        }
        &.btnOk {
          border-right: 1px solid #DBDBDB;
          border-radius: 0 0 0 3px;
        }
        &.btnCancel {
          border-radius: 0 0 3px 0;
        }
      }
    }
    &.isFloating > .options li.opt {
      padding: 12px 6px;
    }
    &.multiple {
      &.isFloating {
        > .options li.opt {
          padding-left: 35px;
        }
        padding-bottom: 43px;
      }
      > .options li.opt.selected span i {
        background-color: #137294;
        box-shadow: none;
        border-color: transparent;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAGCAYAAAD+Bd/7AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNXG14zYAAABMSURBVAiZfc0xDkAAFIPhd2Kr1WRjcAExuIgzGUTIZ/AkImjSofnbNBAfHvzAHjOKNzhiQ42IDFXCDivaaxAJd0xYshT3QqBxqnxeHvhunpu23xnmAAAAAElFTkSuQmCC');
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }
  &.disabled {
    opacity: 0.7;
    cursor: not-allowed;
    > .CaptionCont {
      border-color: #ccc;
      box-shadow: none;
    }
  }
  .select-all {
    border-radius: 3px 3px 0 0;
    position: relative;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
    padding: 8px 0 3px 35px;
    height: 38px;
    cursor: pointer;
    > {
      label, span i {
        cursor: pointer;
      }
    }
    &.partial > span i {
      background-color: #ccc;
    }
  }
  > .optWrapper > .options li.optGroup {
    padding-left: 5px;
    text-decoration: underline;
  }
}