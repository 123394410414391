@import "../variables";
@import "../mixins";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/functions";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/variables";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/mixins";

.dropdown-menu {
  position: absolute;
  min-width: 160px;
  padding: 0px 0;
  margin: 0 0;
  font-size: 13px;
  background-color: #fff;
  border: none;
  border-radius: 2px;
  -webkit-box-shadow: 0 0 7px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 7px 3px rgba(0, 0, 0, 0.1);
  @include media-min($lg) {
    z-index: 10000;
  }
}
.dropdown-item {
  color: $text-main;
  padding: 3px 12px;
  transition: $transition-base;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  min-height: 40px;
  line-height: 40px;
  i {
    transition: $transition-base;
    color: lighten($text-main, 40%);
    font-size: 22px;
    vertical-align: middle;
    margin-right: 6px;
  }
  &:hover {
    background: #f1f1f1;
    i {
      color: $text-main;
    }
  }
  &:focus{
    color: $text-main;
  }
  &:before{
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    vertical-align: middle;
    display: inline-block;
    font: normal normal normal 24px/1 "Material Design Icons";
    font-size: 22px;
    text-rendering: auto;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @include ltr() {
      margin-right: 6px;
    }
    @include rtl() {
      margin-left: 6px;
    }
  }
  &.view-action{
    &:before{
      color: #01bfa5!important;
      content: $mdi-eye;
    }
  }
  &.edit-action{
    &:before{
      color: #00b0ef!important;
      content: $mdi-circle-edit-outline;
    }
  }
  &.delete-action{
    &:before{
      color: #fc5f7d!important;
      content: $mdi-trash-can-outline;
    }
  }
  &.send-action{
    &:before{
      color: #6f42c1!important;
      content: $mdi-page-next-outline;
    }
  }
  &.change-action{
    &:before{
      color: #f47c0b!important;
      content: $mdi-lock-reset;
    }
  }
  &.mark-action{
    &:before{
      color: #17a2b8!important;
      content: $mdi-account-remove;
    }
  }
  &.close-action{
    &:before{
      color: #444!important;
      content: $mdi-close-circle-outline;
    }
  }
  &.test-action{
    &:before{
      color: #0052CC!important;
      content: $mdi-flash-red-eye;
    }
  }
  &.pull-action{
    &:before{
      color: #636e72!important;
      content: $mdi-package-down;
    }
  }
  &.clone-action{
    &:before{
      color: #636e72 !important;
      content: $mdi-bookmark-plus;
    }
  }
  &.hide-action{
    &:before{
      color: #636e72 !important;
      content: $mdi-lock;
    }
  }
  &.unhide-action{
    &:before{
      color: #636e72 !important;
      content: $mdi-lock-open-variant;
    }
  }
  &.print-action{
    &:before{
      color: #17a2b8 !important;
      content: $mdi-print;
    }
  }
  &.manage-records-action{
    &:before{
      font-family: "Font Awesome 5 Pro" !important;
      color: #01bfa5 !important;
      content: $fa-folders;
      font-size: 16px;
    }
  }
}
.dropdown-header {
  display: block;
  padding: 10px 14px;
  font-weight: 600;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: $text-main;
  white-space: nowrap;
}

// .dropdown-toggle,
// .dropdown-menu .dropdown-item {
  // text-transform: capitalize;
// }
.dropdown-toggle::after {
  content: " ";
  display: none;
}


// Dropdown Dialog

.dropdown-dialog {
  .dropdown-dialog-menu {
      @extend .dropdown-menu;
      flex-grow: 1;
      flex-direction: column;
      display: flex;
      position: static;
      box-shadow: none;
      align-items: stretch;
      justify-content: unset;
      overflow: auto;
  }
}

@media (min-width: 993px) {
  .dropdown-dialog {
      .dropdown-dialog-header {
          display: none;
      }
  }
}

@media (max-width: 992px) {
  .dropdown-dialog {
      .dropdown-menu:not(.dropdown-dialog-menu) {
          max-height: 50vh;
          overflow: hidden;
          flex-direction: column;
          .dropdown-dialog-header {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              background: #f6f9fc;
              font-size: 16px;
              font-weight: 600;
              padding: 16px;
              border-bottom: 1px solid #F1F1F5;
              flex-shrink: 0;
              &-title {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 90%;
              }
              &-close {
                  margin: -16px;
                  width: 50px;
                  height: 50px;
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
              }
          }
          .dropdown-dialog-menu {
              .dropdown-item {
                  min-height: 60px;
                  line-height: 60px;
                  font-size: 16px;
                  align-items: center;
                  padding-top: 0;
                  padding-bottom: 0;
              }
          }
          &.open,
          &.show {
              position: fixed !important;
              left: 50% !important;
              top: 50% !important;
              right: unset !important;
              bottom: unset !important;
              transform: translate(-50%, -50%) !important;
              width: calc(100% - 32px);
              display: flex;
              + .dropdown-dialog-backdrop {
                  content: '';
                  position: fixed;
                  inset: 0;
                  background-color: rgba(0, 0, 0, 0.4);
                  z-index: 999;
              }
          }
      }
  }
}

.dropdown-menu.user-dropdown {
  max-height: 85vh;
  overflow: auto;
}