@import "../variables";
@import "../mixins";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/functions";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/variables";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/mixins";

.header {
  background: $main-bg;
  border-color: transparent;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  @include ltr(){
    left: 210px;
    right: 0;
  }
  @include rtl(){
    right: 210px;
    left: 0;
  }
  @include ltr('.shrinked-sidebar'){
    left: 0;
  }
  @include rtl('.shrinked-sidebar'){
    right: 0;
  }
  @include body('.modal-open'){
    z-index: 999;
  }
  z-index: 10000;
  transition: $transition-base;
  padding: 0;
}
.header-nav.navbar-nav {
  li {
    a.nav-link {
      height: 45px;
      line-height: 44px;
      padding: 0 10px;
      font-size: 13px;
      color: rgba(255, 255, 255, 0.72);
      transition: $transition-base;
      &#go-to-website-btn{
        color: $white;
        display: inline-flex;
        align-items: center;
        line-height: 1;
      }
      &:hover {
        color: rgba(255, 255, 255, 1);
        background: rgba(0, 0, 0, 0.1);
      }
      .nav-item-icon {
        font-size: 22px;
        line-height: 46px;
      }
      .user-avatar {
        font-size: 29px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 2px;
      }
      .badge {
          position: absolute;
          border-radius: 50%;
          min-width: 15px;
          padding: 0;
          line-height: 15px;
          text-align: center;
          min-height: 15px;
          top: 7px;
          @include ltr(){
            right: 4px;
          }
        @include rtl(){
          left: 4px;
          right: auto;
        }
      }
    }
    .drop-cont {
      display: inline-block;
      line-height: 12px;
      vertical-align: middle;
      margin-right: 8px;
      p {
        line-height: 12px;
        opacity: 0.7;
        font-size: 11px;
        margin: 4px 0 0;
      }
    }
    &.show {
        .nav-link {
            background: rgba(0, 0, 0, 0.1);
        }
    }
    .user-dropdown {
      //right: 10px;
      .dropdown-item{
        i{
          position: relative;
          top: -2px;
        }
      }
    }
  }
}

.header-elements {
  margin-bottom: 0;
  z-index: 50;
}

#shrink-sidebar {
  @include ltr(){
    float: left;
  }
  @include rtl(){
    float: right;
  }
  height: 45px;
  text-align: center;
  line-height: 45px;
  width: 45px;
  background: rgba(0, 0, 0, 0.1);
  transition: $transition-base;
  color: #fff;
  @include body('.shrinked-sidebar'){
    width: 60px;
  }
  i {
    font-size: 24px;
    &:before{
      @include ltr('.shrinked-sidebar'){
        transform: rotateY(-180deg);
      }
      @include rtl(){
        transform: rotateY(-180deg);
      }
      @include rtl('.shrinked-sidebar'){
        transform: rotateY(0deg);
      }
    }
  }
}

.header-breadcrumb {
    @include ltr(){
      margin: 0 0 0 15px;
    }
    @include rtl(){
      margin: 0 15px 0 0;
    }
    padding: 0;
    background: none;
    position: static !important;
    .breadcrumb-item {
        color: #fff;
        line-height: 44px;
        font-size: 14px;
        a {
            color: rgba(255, 255, 255, 0.72);
            transition: $transition-base;
            height: 45px;
            display: inline-block;
            line-height: 45px;
            &:hover {
                color: #fff;
            }
        }
    }
    .breadcrumb-item + .breadcrumb-item{
      @include rtl(){
        padding-left: 0;
        padding-right: 0.5rem;
      }
      &::before {
        font-family: 'Material Design Icons';
        color: rgba(255, 255, 255, 0.72);
        @include ltr(){
          content: $mdi-chevron-right !important;
        }
        @include rtl(){
          padding-right: 0;
          padding-left: 0.5rem;
          content: $mdi-chevron-left !important;
          position: relative;
          top: 1px;
          float: right;
        }
      }
    }
}


@include media-breakpoint-down(md) {
  .header {
    @include ltr(){
      left: 0px;
    }
    @include rtl(){
      right: 0px;
    }
  }
  .header-breadcrumb {
    margin: 0;
    white-space: nowrap;
    //width: calc( 100vw - 135px);
    flex: 1;
    height: 46px;
    overflow-x: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
    overflow-y: hidden;
    //flex-wrap: nowrap;
    padding-left: 10px;
    padding-right: 10px;
    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: transparent;
    }
    &:after{
      content: '';
      clear: both;
      display: block;
    }
    li {
      font-size: 12px !important;
      flex: 0 0 auto;
      display: inline-block;
      &:nth-last-child(-n+2) {
        flex: 0 0 auto;
        display: inline-block;
      }
      &:nth-last-child(-2n+2):before {
        flex: 0 0 auto;
        display: inline-block;
      }
    }
  }
  .collapse-sidebar #shrink-sidebar i:before {
    transform: rotateY(180deg);
  }
}
@include media-breakpoint-down(sm) {
  .header-nav.navbar-nav {
    flex: 1;
    flex-grow: 0;
  }
  .header-nav.navbar-nav li .drop-cont {
    display: none;
  }
}


