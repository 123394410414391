@import "../variables";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/functions";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/variables";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/mixins";

.notifications-list{
  .notification-item{
    background: $light;
    padding: 15px;
    border-width: 5px !important;
    margin-bottom: 8px;
    .dismiss-icon{
      position: relative;
      margin-top: -8px;
      a{
        display: flex;
        align-items: center;
        span{
          opacity: 0;
          transition: 0.3s all;
          @include media-breakpoint-down(md) {
            display: none;
          }
        }
        i{
          font-size: 22px;
        }
        &:hover{
          span{
            opacity: 1;
          }
        }
      }
    }
    .notification-meta{
      font-size: 12px;
    }
    &:last-child{
      margin-bottom: 0;
    }
    &.notification-item-hover{
      background: #fff;
      margin-bottom: 0;
      .notification-meta{
        .link{
          display: none;
        }
      }
      &:hover{
        background-color: $light;
        .link{
          display: block;
        }
        .date{
          display: none;
        }
      }
    }
    &.notification-item-group{
      .notification-meta{
        .link{
          display: block !important;
        }
      }
    }
  }
}

.notifications-loader{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  background: $white;
  min-height: 120px;
  z-index: 0;
  .loading-partial{
    background: $white;
    width: 100%;
    height: 90%;
    align-items: center;
    justify-content: center;
    min-height: 120px;
  }
}

.notifications-bg{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: $white;
  z-index: -1;
}

.notifications-dropdown-wrap.loading.show{
  min-height: 150px;
  display: flex;
  flex-direction: column;
  .alert{
    display: none;
  }
  .notifications-wrap{
    flex-grow: 1;
    min-height: 70px;
  }
  .notifications-loader{
    display: flex;
  }
  .notifications-list{
    display: none;
    min-height: 150px;
  }
  .notifications-title,
  .notifications-btn{
    z-index: 1;
    position: relative;
  }
}
