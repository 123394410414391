@import "../variables";
@import "../mixins";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/functions";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/variables";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/mixins";

.modal-header{
  background: $light;
  color: $inactive;
  padding: .65rem 1rem;
  h5{
    font-size: 1rem;
    font-weight: normal;
  }
  .close{
    @include rtl(){
      margin: -1rem auto -1rem -1rem;
    }
  }
}
.modal-footer{
  justify-content: center;
  >:not(:last-child) {
    @include rtl(){
      margin-left: .25rem;
    }
  }
  >:not(:first-child) {
    @include rtl(){
      margin-right: .25rem;
    }
  }
}
