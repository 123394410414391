@import "../variables";
@import "../mixins";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/functions";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/variables";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/mixins";

.status {
  color: $text-main;
  font-size: 14px;
  white-space: nowrap;
  display: flex;
  line-height: 1;
  i {
    font-size: 20px;
    @include ltr(){
      margin-right: 8px;
    }
    @include rtl(){
      margin-right: 0;
      margin-left: 8px;
    }
    &.cir {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      position: relative;
      top: 1px;
    }
  }
  .status-text{
    a{
      text-decoration: underline;
    }
  }
  small {
    position: relative;
    display: block;
    line-height: 11px;
    @include ltr(){
      text-align: right;
    }
    @include rtl(){
      text-align: left;
    }
  }
}
@each $color, $value in $theme-colors {
  .status-#{$color} {
    i {
      color: $value;

      &.cir {
        background: $value;
      }
    }
  }
}
