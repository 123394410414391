@import "../../variables";
@import "../../../../../node_modules/bootstrap-v4-rtl/scss/functions";
@import "../../../../../node_modules/bootstrap-v4-rtl/scss/variables";

@import "single";
@import "multiple";
.select2-add-btn-clone{
  background-color: $light;
  padding: 6px;
  cursor: pointer;
  border-top: 1px solid $input;
  transition: $transition-base;
  &:hover{
    background-color: $disabled;
  }
}
.select2-container {
    box-sizing: border-box;
    display: block;
    margin: 0;
    background: $white;
    position: relative;
    vertical-align: middle;
    .select2-selection--single {
      box-sizing: border-box;
      cursor: pointer;
      display: block;
      height: 38px;
      user-select: none;
      -webkit-user-select: none;
      .select2-selection__rendered {
        display: block;
        padding-left: 12px;
        padding-right: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .select2-selection__clear {
        position: relative;
      }
      &.profile-select2{
        height: 60px;
        .select2-selection__rendered {
          line-height: calc(1.5em + .75rem + 20px);
          .select2-selection__clear{
            margin-top: 19px;
          }
        }
      }
    }
    &.select2-container--disabled{
      background: #e9ecef;
      cursor: default;
      pointer-events: none;
    }
    &[dir="rtl"] .select2-selection--single .select2-selection__rendered {
      padding-right: 12px;
      padding-left: 20px;
    }
    .select2-selection--multiple {
      box-sizing: border-box;
      cursor: pointer;
      display: block;
      min-height: 32px;
      user-select: none;
      -webkit-user-select: none;
      .select2-selection__rendered {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .select2-search--inline {
      float: left;
      .select2-search__field {
        box-sizing: border-box;
        border: none;
        font-size: 100%;
        margin-top: 5px;
        padding: 0;
        height: 36px;
        font-weight: 400;
        @media screen and (-webkit-touch-callout: none) {
          font-size: 16px;
        }
        &::-webkit-search-cancel-button {
          -webkit-appearance: none;
        }
        &:disabled{
          background: #e9ecef;
        }
      }
    }
  }

// Multiple Search box fix
.select2-container .select2-selection__rendered > *:first-child.select2-search--inline {
  width: 100% !important;
}
.select2-container .select2-selection__rendered > *:first-child.select2-search--inline .select2-search__field {
  width: 100% !important;
}

.select2-dropdown {
    background-color: white;
    border: 1px solid $gray-400;
    border-radius: 0;
    box-sizing: border-box;
    display: block;
    position: absolute;
    left: -100000px;
    width: 100%;
    // use 1051 for modals
    z-index: 1051 !important;
  }

  .select2-results {
    display: block;
  }

  .select2-results__options {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .select2-results__option {
    padding: 6px;
    user-select: none;
    -webkit-user-select: none;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    &[aria-selected] {
      cursor: pointer;
    }
  }

  .select2-container--open {
    .select2-dropdown {
      left: 0;
    }
    .select2-dropdown--above {
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .select2-dropdown--below {
      border-top: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  .select2-search--dropdown {
    display: block;
    padding: 4px;
    .select2-search__field {
      padding: 4px;
      width: 100%;
      box-sizing: border-box;
      @media screen and (-webkit-touch-callout: none) {
        font-size: 16px;
      }
      &::-webkit-search-cancel-button {
        -webkit-appearance: none;
      }
    }
    &.select2-search--hide {
      display: none;
    }
  }

  .select2-close-mask {
    border: 0;
    margin: 0;
    padding: 0;
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    opacity: 0;
    z-index: 99;
    background-color: #fff;
    filter: alpha(opacity = 0);
  }

  .select2-hidden-accessible {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
  }




// basic
.select2-container {
  *:focus {
    outline: 0;
  }
}

.select2-container--bootstrap4 {
  .select2-results__message {
    color: #424649;
    font-size: 14px;
  }
  // input box
  .select2-selection {
    border: $input-border-width solid $input-border-color;
    @include border-radius($border-radius , 0);
    width: 100%;
  }

  // focused input box
  &.select2-container--focus {
    .select2-selection {
      border-color: $primary !important;
      box-shadow: $input-focus-box-shadow !important;
    }

    // when open, hide bottom border
    &.select2-container--open .select2-selection {
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  // validated input box
  select.is-invalid ~ & .select2-selection {
    border-color: $danger;
  }
  select.is-valid ~ & .select2-selection {
    border-color: $success;
  }

  // dropdown
  .select2-dropdown {
    border-color: $input-border-color;

    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    // selected item
    .select2-results__option[aria-selected="true"] {
      background-color: darken($light, 5%);
    }
  }

  // mouse hovered item
  .select2-results__option--highlighted,
  .select2-results__option--highlighted.select2-results__option[aria-selected="true"] {
    background-color: $primary;
    color: $light;
  }

  // for <optgroup>
  .select2-results__option[role="group"] {
    padding: 0;
  }

  // fixes vertical overflow
  .select2-results__options {
    max-height: 15em;
    overflow-y: auto;
    .select2-results__options{
      max-height: none;
    }
  }

  .select2-results__group {
    padding: 6px;
    display: list-item;
    color: $inactive;
  }

  // all clear button
  .select2-selection__clear {
    width: 1.2em;
    height: 1.2em;
    line-height: 1.15em;
    padding-left: 0.3em;
    border-radius: 100%;
    background-color: $secondary;
    color: $inactive;
    float: right;
    margin: 9px -5px 0 10px;

    &:hover {
      background-color: $dark;
      color: $light;
    }
  }
}

.select-stretched{
  select{
    height: 100%;
  }
  .select2-container{
    height: 100%;
    .select2-selection{
      height: 100%;
    }
    .select2-selection__rendered{
      height: 100%;
      line-height: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}


// Select2 Multiple Checkboxes

//.select2-container--bootstrap4multiple .select2-selection--single {
//  min-height: calc(1.5em + 0.75rem + 2px) !important;
//}
//
//.select2-container--bootstrap4multiple .select2-selection--single .select2-selection__placeholder {
//  color: #8186b5;
//  line-height: calc(1.5em + 0.75rem + 2px);
//}
//
//.select2-container--bootstrap4multiple .select2-selection--single .select2-selection__arrow {
//  position: absolute;
//  top: 50%;
//  right: 3px;
//  width: 20px;
//}
//
//.select2-container--bootstrap4multiple .select2-selection--single .select2-selection__arrow b {
//  top: 60%;
//  border-color: #485563 transparent transparent transparent;
//  border-style: solid;
//  border-width: 5px 4px 0 4px;
//  width: 0;
//  height: 0;
//  left: 50%;
//  margin-left: -4px;
//  margin-top: -2px;
//  position: absolute;
//}
//
//.select2-container--bootstrap4multiple .select2-selection--single .select2-selection__rendered {
//  line-height: calc(1.5em + 0.75rem + 2px);
//}
//
//.select2-container--bootstrap4multiple .select2-selection--multiple {
//  min-height: calc(1.5em + 0.75rem + 2px) !important;
//}
//
//.select2-container--bootstrap4multiple .select2-selection--multiple .select2-selection__rendered {
//  box-sizing: border-box;
//  list-style: none;
//  margin: 0;
//  padding: 0 5px !important;
//  width: 100%;
//}
//
//.select2-container--bootstrap4multiple .select2-selection--multiple .select2-selection__clear {
//  margin: 9px 4px 0 10px;
//}
//
//.select2-container--bootstrap4multiple .select2-selection--multiple .select2-selection__choice {
//  color: #485563;
//  border: 1px solid #aac6e3;
//  border-radius: 0.2rem;
//  padding: 0;
//  background: #E4EBF2;
//  padding-right: 5px;
//  font-size: 15px;
//  cursor: pointer;
//  float: left;
//  margin-top: 0.3em;
//  margin-right: 5px;
//}
//
//.select2-container--bootstrap4multiple .select2-selection--multiple .select2-selection__choice__remove {
//  color: #aac6e3;
//  font-weight: bold;
//  margin-left: 3px;
//  margin-right: 1px;
//  padding-right: 3px;
//  padding-left: 3px;
//  float: left;
//}
//
//.select2-container--bootstrap4multiple .select2-selection--multiple .select2-selection__choice__remove:hover {
//  color: #485563;
//}
//
//.select2-container--bootstrap4multiple .select2-selection {
//  border: 1px solid #ced4da;
//  width: 100%;
//}
//
//.select2-container--bootstrap4multiple.select2-container--focus .select2-selection {
//  border-color: $primary;
//  box-shadow: $input-focus-box-shadow;
//}
//
//.select2-container--bootstrap4multiple.select2-container--focus.select2-container--open .select2-selection {
//  border-bottom: none;
//  border-bottom-left-radius: 0;
//  border-bottom-right-radius: 0;
//}
//
//select.is-invalid ~ .select2-container--bootstrap4multiple .select2-selection {
//  border-color: #FC5F7D;
//}
//
//select.is-valid ~ .select2-container--bootstrap4multiple .select2-selection {
//  border-color: #13B272;
//}
//
//.select2-container--bootstrap4multiple .select2-dropdown {
//  border-color: #ced4da;
//  border-top: none;
//  border-top-left-radius: 0;
//  border-top-right-radius: 0;
//}
//
//.select2-container--bootstrap4multiple .select2-dropdown .select2-results__option[aria-selected="true"] {
//  background-color: #e3ecf6;
//}
//
//.select2-container--bootstrap4multiple .select2-results__option--highlighted,
//.select2-container--bootstrap4multiple .select2-results__option--highlighted.select2-results__option[aria-selected="true"] {
//  background-color: #00B0EF;
//  color: #F6F9FC;
//}
//
//.select2-container--bootstrap4multiple .select2-results__option[role="group"] {
//  padding: 0;
//}
//
//.select2-container--bootstrap4multiple .select2-results__options {
//  max-height: 15em;
//  overflow-y: auto;
//}
//
//.select2-container--bootstrap4multiple .select2-results__group {
//  padding: 6px;
//  display: list-item;
//  color: #E4EBF2;
//}
//
//.select2-container--bootstrap4multiple .select2-selection__clear {
//  width: 1.2em;
//  height: 1.2em;
//  line-height: 1.15em;
//  padding-left: 0.3em;
//  border-radius: 100%;
//  background-color: #E4EBF2;
//  color: #F6F9FC;
//  float: right;
//  margin: 9px -5px 0 10px;
//}
//
//.select2-container--bootstrap4multiple .select2-selection__clear:hover {
//  background-color: #485563;
//}
//
//.select2-container--bootstrap4multiple .select2-selection{
//  position: relative;
//}
//
//.select2-container--bootstrap4multiple .select2-selection:after{
//  content: '';
//  top: 48%;
//  border-color: #485563 transparent transparent;
//  border-style: solid;
//  border-width: 5px 4px 0;
//  width: 0;
//  height: 0;
//  right: 10px;
//  position: absolute;
//  z-index: 999;
//}
//
//.select2-container--bootstrap4multiple .select2-selection__rendered .select2-selection__choice{
//  display: none;
//}
//
//.select2-container--bootstrap4multiple .select2-selection__rendered{
//  position: relative;
//}
//
//.select2-container--bootstrap4multiple .select2-search--inline{
//  position: static;
//  width: 100%;
//  float: none;
//  height: 36px;
//  top: 0;
//  left: 0;
//  z-index: 22;
//}
//
//
//.select2-container--bootstrap4multiple.select2-container--below  .select2-search--inline{
//  position: absolute;
//}
//
//.select2-container--bootstrap4multiple.select2-container--below .select2-search--inline .select2-search__field{
//  /*background: none;*/
//  opacity: 0;
//  position: absolute;
//  top: 0;
//  left: 0;
//  padding: 0 5px;
//  width: 100% !important;
//  height: 100%;
//  z-index: 22;
//}
//
//.select2-container--bootstrap4multiple.select2-container--below.select2-container--open .select2-search--inline .select2-search__field{
//  opacity: 1;
//}
//
//.select2-container--bootstrap4multiple .select2-selection--multiple .select2-selection__rendered{
//  padding: 0 !important;
//}
//
//.select2-container--bootstrap4multiple .select2-results__option {
//  padding-right: 20px;
//  vertical-align: middle;
//}
//
//.select2-container--bootstrap4multiple .select2-results__option:before {
//  content: "";
//  display: inline-block;
//  position: relative;
//  height: 20px;
//  width: 20px;
//  border: 2px solid #e9e9e9;
//  border-radius: 4px;
//  background-color: #fff;
//  margin-left: 10px;
//  margin-right: 10px;
//  vertical-align: middle;
//}
//
//.select2-container--bootstrap4multiple .select2-results__option[aria-selected=true]:before {
//  font-family: 'Font Awesome 5 Free';
//  font-weight: bold;
//  font-size: 10px;
//  padding-top: 2px;
//  content: "\f00c";
//  color: #fff;
//  background-color: #333;
//  border: 0;
//  display: inline-block;
//  padding-left: 4px;
//}
//
//.select2-container--bootstrap4multiple.select2-container--default .select2-results__option[aria-selected=true] {
//  background-color: #fff;
//}
//
//.select2-container--bootstrap4multiple.select2-container--default .select2-results__option--highlighted[aria-selected] {
//  background-color: #eaeaeb;
//  color: #272727;
//}
//
//.select2-container--bootstrap4multiple.select2-container--default .select2-selection--multiple {
//  margin-bottom: 10px;
//}
//
//.select2-container--bootstrap4multiple.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
//  border-radius: 4px;
//}
//
//.select2-container--bootstrap4multiple.select2-container--default.select2-container--focus .select2-selection--multiple {
//  border-color: #333;
//  border-width: 2px;
//}
//
//.select2-container--bootstrap4multiple.select2-container--default .select2-selection--multiple {
//  border-width: 2px;
//}

// Icon Picker
.icon-picker-dropdown{
  @include media-breakpoint-up(md) {
    width: 199px !important;
  }
  .select2-results__options{
    &:after{
      content: '';
      display: block;
      clear: both;
    }
    .select2-results__option{
      display: inline-block;
      float: left;
      width: 25%;
      height: 45px;
      font-size: 20px;
      text-align: center;
      border: 1px solid rgba(221, 221, 221, 0.35);
      @include media-breakpoint-up(md) {
        width: 45px;
      }
      &.select2-results__message{
        width: 100%;
        font-size: inherit;
        height: inherit;
        text-align: inherit;
        border: none;
      }
    }
  }
}

.icon-picker-dropdown-container{
  .select2-selection__rendered{
    line-height: 1 !important;
    display: flex !important;
    align-items: center;
    height: 100%;
    justify-content: flex-start;
    i{
      font-size: 22px;
    }
  }
  .select2-container .select2-selection--single .select2-selection__rendered{
    display: flex;
    align-items: center;
    height: 100%;
  }
}









.rtl{

  .select2-container--bootstrap4 .select2-selection--single .select2-selection__arrow {
    left: 3px;
    right: auto;
  }

  .select2-container--bootstrap4 .select2-selection--single .select2-selection__arrow b {
    border-color: #485563 transparent transparent transparent;
    border-width: 5px 4px 0 4px;
    right: 50%;
    left: auto;
    margin-right: -4px;
    margin-left: auto;
  }

  .select2-container--bootstrap4 .select2-selection--multiple .select2-selection__clear {
    //margin: 9px 10px 0 4px;
    margin-left: 10px;
    margin-right: 0;
    left: 0;
    right: inherit;
    padding: 0;
    justify-content: center;
  }

  .select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice {
    padding-left: 0;
    padding-right: 5px;
    //float: right;
    margin-left: 5px;
    //margin-right: auto;
  }

  .select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice:after {
    content: '';
    display: block;
    clear: both;
  }

  .select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice img{
    float: right;
  }

  .select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice__remove {
    float: left;
    width: 16px;
    height: 16px;
    text-align: center;
    padding: 0;
  }

  .form-group-icon .select2-container .select2-selection--single .select2-selection__rendered {
    padding-right: 35px;
    padding-left: 5px;
  }

  // .select2-container .select2-selection--multiple .select2-selection__rendered {
    // flex-direction: row-reverse;
  // }

  .form-group-icon .select2-container .select2-selection--multiple .select2-selection__rendered {
    padding-right: 35px !important;
    padding-left: 5px !important;
  }

  .select2-container .select2-search--inline {
    float: right;
  }

  .select2-container .select2-search--inline .select2-search__field,
  .select2-search--dropdown .select2-search__field{
    text-align: right;
    direction: rtl;
  }

  .select2-container--open .select2-dropdown--above {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .select2-container--open .select2-dropdown--below {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  .select2-close-mask {
    right: 0;
    left: auto;
  }

  .select2-container--bootstrap4.select2-container--focus.select2-container--open .select2-selection {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .select2-container--bootstrap4 .select2-dropdown {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  .select2-container--bootstrap4 .select2-selection__clear {
    padding-right: 0.3em;
    padding-left: 0;
    float: left;
    margin: 9px 10px 0 0;
  }

  .select2-container--bootstrap4multiple .select2-selection:after{
    left: 10px;
    right: inherit;
  }

  .select2-results__options{
    direction: rtl;
  }

  .select2-container--bootstrap4multiple .select2-results__option {
    padding-right: 5px;
    padding-left: 20px;
  }

  .select2-container--bootstrap4multiple .select2-results__option:before{
    text-align: center;
  }

  .select2-container--bootstrap4multiple .select2-results__option[aria-selected=true]:before{
    padding-left: 0;
  }

  .select2-container .select2-selection--single.profile-select2 .select2-selection__rendered span:last-child{
    display: flex;
    flex-direction: row-reverse;
    padding-top: 3px;
  }

  .select2-container .select2-selection--single.profile-select2 .select2-selection__rendered,
  .select2-container .select2-selection--single.profile-select2 .select2-selection__rendered .select2-selection__placeholder{
    height: 60px;
    line-height: calc(1.5em + .75rem + 17px);
  }

  .select2-container .select2-selection--single.profile-select2 .select2-selection__rendered{
    padding-right: 15px;
  }

  .select2-container .select2-selection--single.profile-select2 .select2-selection__rendered span:last-child img{
    margin-right: 0 !important;
    position: relative;
    top: 9px;
  }

  .select2-container .select2-selection--single.profile-select2 .select2-selection__clear {
    margin: 9px 10px 0 12px;
  }

  .select2-container .select2-selection--single .select2-selection__rendered{
    padding-left: 30px;
    padding-right: 12px;
  }

  // .icon-picker-dropdown-container{
    // .select2-selection__rendered{
      // justify-content: flex-end;
    // }
  // }

}


.select2-container--bootstrap4 .select2-selection--multiple .select2-search__field,
.select2-search--dropdown .select2-search__field {
  @supports (-webkit-touch-callout: none) { 
    font-size: 16px;
   }
}