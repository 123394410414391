@import './icons_materialdesignicons';

// ---------------
// deprecated
// ---------------

.mdi-cash::before,
.mdi-cash-usd::before {
    content: '\F0114';
}

.mdi-poll-box::before {
    content: '\F154D';
}

.mdi-settings-outline::before {
    content: "\F08BB";
}
  
.mdi-account-badge-horizontal::before {
    content: "\F0E0D";
}

.mdi-file-document-box::before {
    content: "\F021A";
}

.mdi-file-document-box-plus::before {
    content: "\F0EAA";
}
  
// ---------------
// overrides newest version
// ---------------

.mdi-receipt::before {
    content: '\F0449';
}