@import "../variables";
@import "../mixins";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/functions";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/variables";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/mixins";
.pages-head {
  padding: 12px 0;
  background-color: white;
  position: fixed;
  z-index: 9998;
  min-height: 60px;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 0 2px 1px rgba(0,0,0,.1);
  @include ltr(){
    right: 0px;
    left: 210px;
  }
  @include rtl(){
    left: 0px;
    right: 210px;
  }
  @include ltr('.shrinked-sidebar'){
    left: 60px;
  }
  @include rtl('.shrinked-sidebar'){
    right: 60px;
    left: 0;
  }
  @include media-max($lg) {
    left: 0px !important;
    right: 0 !important;
    width: 100%;
  }
  @include body('.modal-open'){
    z-index: 999;
  }
  #pagination-jump{
    width: 120px;
    text-align: center;
    outline: none;
    box-shadow: none;
    &::placeholder{
      text-align: center;
    }
  }
  #select-trigger-mob{
    padding-left: 12px;
    padding-right: 12px;
  }
  &.fixed {
    padding: 8px 0;
    .pagination-num {
      height: 31px;
      line-height: 31px;
    }
  }
  .pages-head-title {
    h2 {
      font-size: 18px;
      margin: 0;
      .status {
        position: relative;
        right: -5px;
        display: inline-flex;
        padding: 0 5px;
        vertical-align: middle;
      }
    }
  }
  .pages-head-btns{
    & > a{
      font-weight: 500;
      @include rtl(){
        margin-left: 20px;
        margin-right: 0;
      }
      @include ltr(){
        margin-left: 0;
        margin-right: 20px;
      }
      &:first-child{
        @include media-breakpoint-down(md) {
          @include ltr(){
            margin-left: 0;
          }
          @include rtl(){
            margin-right: 0;
          }
        }
      }
      @include ltr(){
        &:last-child{
          margin-right: 0;
        }
      }
      @include rtl(){
        &:last-child{
          margin-left: 0;
        }
      }
      @include media-breakpoint-down(md) {
        @include ltr(){
          margin-left: 10px;
          margin-right: 0;
        }
        @include rtl(){
          margin-left: 0;
          margin-right: 10px;
        }
      }
    }
  }
}

.action-buttons {
  @include ltr(){
    text-align: right;
  }
  @include rtl(){
    text-align: left;
  }
  .btn {
    font-weight: 500;
  }
}
.select-items {
  cursor: pointer;
  .custom-control-label::before {
    @include ltr(){
      left: -20px;
    }
    @include rtl(){
      right: -20px;
      left: auto;
    }
  }
  .custom-control-label::after {
    @include ltr(){
      left: -20px;
    }
    @include rtl(){
      right: -20px;
      left: auto;
    }
  }
}
.pagination-num {
  height: 38px;
  display: inline-block;
  line-height: 38px;
  padding: 0 10px;
  @include ltr(){
    margin-left: 10px;
    border-left: 1px solid $secondary;
  }
  @include rtl(){
    margin-right: 10px;
    margin-left: auto;
    border-right: 1px solid #e4ebf2;
    border-left: none;
  }
  &.no-border{
    border: none;
    margin-left: 0;
    margin-right: 0;
    @include ltr(){
      padding-left: 0;
      padding-right: 15px;
    }
    @include rtl(){
      padding-right: 0;
      padding-left: 15px;
    }
  }
}

@include media-max($lg) {
.pages-head-touch {
  .pages-head {
      padding-top: 0;
      padding-bottom: 0;
      min-height: 50px;
      > .container-fluid {
        padding-left: 0;
        padding-right: 0;
        > .row {
          margin-left: 0;
          margin-right: 0;
          > {
            .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto, .col-xxl, .col-xxl-1, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-auto, .col-xxxl, .col-xxxl-1, .col-xxxl-10, .col-xxxl-11, .col-xxxl-12, .col-xxxl-2, .col-xxxl-3, .col-xxxl-4, .col-xxxl-5, .col-xxxl-6, .col-xxxl-7, .col-xxxl-8, .col-xxxl-9, .col-xxxl-auto {
              padding-left: 0;
              padding-right: 0;
              .btn {
                padding-top: 0;
                padding-bottom: 0;
                min-width: 50px;
                min-height: 50px;
                line-height: 48px;
                &.dropdown-toggle {
                  min-width: auto;
                }
                &.btn-icon i:not(.caret) {
                  position: static;
                  background: transparent;
                  width: auto;
                  height: auto;
                }
                .custom-control {
                  height: 20px;
                  margin-top: 13px;
                }
              }
              .select-btn-back {
                padding-top: 0;
                padding-bottom: 0;
                min-width: 50px;
                min-height: 50px;
                line-height: 48px;
                i {
                  line-height: 48px;
                  height: 50px;
                }
              }
              .pages-head-btns {
                > a {
                  @include ltr(){
                    margin-left: 0;
                    margin-right: 0;
                  }
                  @include rtl(){
                    margin-right: 0;
                    margin-left: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
