.progress-wrap{
  margin-left: auto;
  margin-right: auto;
  font-size: 0.85rem;
  line-height: 1;
  small{
    display: block;
  }
  small.first{
    margin-bottom: 5px;
  }
  small.last{
    margin-top: 5px;
  }
  .progress-sm{
    height: 2px;
  }
  .progress-md{
    height: 4px;
  }
  &.progress-wrap-sm{
    max-width: 130px;
  }
  &.progress-wrap-md{
    small{
      font-size: 90%;
    }
    small.first{
      margin-bottom: 10px;
    }
    small.last{
      margin-top: 10px;
    }
  }
}